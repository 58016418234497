import {v4} from 'uuid';
import _ from 'lodash';
import {User} from '../entity/user';
import {constructFromInterface} from 'app/core/logic/map.logic';
import {IApiKey, IApiKeyInput, IApiKeyMutationInput} from "../../../core/graphql/generated/types";
import {GetDateTimeScalar, SetDateTimeScalar} from "../../../core/logic/date-scalars";


export class ApiKey implements IApiKey {
  id: number = 0;
  subscriberId: number = 0;
  partnerId: number = 0;
  userId: number = 0;
  user: User;
  publicKey: string = '';
  secretKey: string = '';
  description: string = '';
  created: string = "";

  private _created: Date;

  get Created() {return this._created;}

  set Created(date: Date) {
    this._created = date;
    this.created = SetDateTimeScalar(date);
  }

  lastUsed: string = "";
  private _lastUsed: Date;

  get LastUsed() {return this._lastUsed;}

  set LastUsed(date: Date) {
    this._lastUsed = date;
    this.lastUsed = SetDateTimeScalar(date);
  }

  isValid() {
    return (this.publicKey ?? '').trim().length > 0 && (this.secretKey ?? '').trim().length > 0 && (this.description ?? '').trim().length > 0;
  }

  constructor(key: IApiKey = null) {
    if (key === null) {
      return;
    }

    constructFromInterface(this, key);

    if (key.lastUsed) {this._lastUsed = GetDateTimeScalar(key.lastUsed);}
    if (key.created) {this._created = GetDateTimeScalar(key.created);}
    if (key.user) {
      this.user = new User(key.user);
    }
  }

  public static new(): ApiKey {
    return new ApiKey({
      id: null,
      subscriberId: null,
      partnerId: null,
      userId: null,
      user: null,
      publicKey: v4(),
      description: null,
      created: null,
      lastUsed: null,
    });
  }

  public convertToInput(isNew = false): IApiKeyInput {
    return {
      id: isNew ? null : +this.id,
      publicKey: this.publicKey,
      secretKey: this.secretKey,
      description: this.description,
    };
  }

  public createMutationInput(originalApiKey: ApiKey = null): IApiKeyMutationInput {
    let mutationInput: IApiKeyMutationInput = {id: this.id, apiKey: null};
    mutationInput.apiKey = _.isEqual(this.convertToInput(), originalApiKey?.convertToInput()) ? null : this.convertToInput();

    return mutationInput;
  }
}
