import {IRelationParameter, IRelationParameterInput} from "../../../core/graphql/generated/types";


export class RelationParameter implements IRelationParameter {
  id: number;
  subscriberId: number;
  relationId: number;
  key: string;
  value: string;

  constructor(parameter: IRelationParameter = null) {
    if (parameter === null) {
      return;
    }

    this.id = parameter.id;
    this.subscriberId = parameter.subscriberId;
    this.relationId = parameter.relationId;
    this.key = parameter.key;
    this.value = parameter.value;
  }

  convertToInput() {
    return {
      id: this.id ? +this.id : null,
      key: (this.key ?? '').length > 0 ? '' + this.key : null,
      value: (this.value ?? '').length > 0 ? '' + this.value : null,
    } as IRelationParameterInput;
  }

  public static new() {
    return new RelationParameter({
      id: null,
      relationId: null,
      key: '',
      subscriberId: null,
      value: '',
    });
  }
}
