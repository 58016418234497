import {ICashbook, ICashbookReferenceObjectEnum, ICashbookTypeEnum} from "../../../core/graphql/generated/types";
import {GetDateScalar, SetDateScalar} from "../../../core/logic/date-scalars";
import {constructFromInterface} from "../../../core/logic/map.logic";

export class Cashbook implements ICashbook {
  __typename?: "Cashbook";
  date?: string;
  guid: string = "";
  message?: string = "";
  referenceObject?: ICashbookReferenceObjectEnum;
  referenceObjectId?: number = 0;
  subscriberId: number = 0;
  type?: ICashbookTypeEnum = ICashbookTypeEnum.Manual;
  userId?: number = 0;
  value?: number = 0;

  private _date: Date;

  get Date() {return this._date};

  set Date(date: Date) {
    this._date = date;
    this.date = SetDateScalar(date);
  }

  constructor(cashbook: ICashbook = null) {
    if (cashbook === null) {
      return;
    }

    constructFromInterface(this, cashbook);

    if (cashbook.date) {this._date = GetDateScalar(cashbook.date)}
    if (cashbook.referenceObject) { this.referenceObject = cashbook.referenceObject }
    ;
  }

  public static new() {
    return new Cashbook({
      date: null,
      guid: null,
      message: "",
      referenceObject: null,
      referenceObjectId: null,
      subscriberId: null,
      type: null,
      userId: null,
      value: 0
    });
  }
}
