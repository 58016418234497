import _ from 'lodash';
import moment from 'moment-timezone';
import {Product} from '../article/product';
import {Stock} from '../article/stock';
import {Address} from '../relation/address';
import {Relation} from '../relation/relation.class';
import {Country} from '../general/country';
import {Document} from '../general/document';
import {Company} from '../entity/company';
import {Invoice} from './invoice';
import {constructFromInterface} from 'app/core/logic/map.logic';
import {IProductDelivery, IProductDeliveryInput, IProductDeliveryMutationInput, IProductDeliverySubline, IProductDeliverySublineInput, IProductDeliverySublineMutationInput} from "../../../core/graphql/generated/types";
import {MutationInputObject} from "../system/mutation-object";
import {GetDateScalar, GetDateTimeScalar, SetDateScalar, SetDateTimeScalar} from "../../../core/logic/date-scalars";
import {countries} from "../../data/country";
import {translate} from "@jsverse/transloco";
import {marker} from "@nyffels/transloco-keys-manager/marker";


export class ProductDelivery implements IProductDelivery {
  id: number = 0;
  subscriberId: number = 0;
  number: number = 0;
  Number: string = '';
  date: string = "";
  private _date: Date;

  get Date() {return this._date;}

  set Date(date: Date) {
    this._date = date;
    this.date = SetDateScalar(date);
  }

  companyId: number = 0;
  companyName: string = '';
  companyVatNumber: string = '';
  companyAddressStreet: string = '';
  companyAddressNumber: string = '';
  companyAddressCity: string = '';
  companyAddressZip: string = '';
  companyAddressCounty: string = '';
  companyAddressCountryId: number = 0;
  companyAddressCountry: Country;
  company: Company;
  relationId: number = 0;
  relationName: string = '';
  relationVatNumber: string = '';
  relationAddressStreet: string = '';
  relationAddressNumber: string = '';
  relationAddressCity: string = '';
  relationAddressZip: string = '';
  relationAddressCounty: string = '';
  relationAddressCountryId: number = 0;
  relationAddressCountry: Country;
  relation: Relation;
  status: ProductDeliveryStatusEnum = ProductDeliveryStatusEnum.CONCEPT;
  note: string = '';
  created: string = "";
  private _created: Date;

  get Created() {return this._created;}

  set Created(date: Date) {
    this._created = date;
    this.created = SetDateTimeScalar(date);
  }

  updated: string = "";
  private _updated: Date;

  get Updated() {return this._updated;}

  set Updated(date: Date) {
    this._updated = date;
    this.updated = SetDateTimeScalar(date);
  }

  relationReference: string = '';
  sublines: ProductDeliverySubline[] = [];
  documents: Document[] = [];

  get Valid(): boolean {
    return (
      moment(this.Date)
        .isValid() &&
      (this.companyName ?? '').trim().length > 0 &&
      (this.companyVatNumber ?? '').trim().length > 0 &&
      (this.companyAddressStreet ?? '').trim().length > 0 &&
      (this.companyAddressNumber ?? '').trim().length > 0 &&
      (this.companyAddressCity ?? '').trim().length > 0 &&
      (this.companyAddressZip ?? '').trim().length > 0 &&
      !!this.companyAddressCountryId &&
      (this.relationName ?? '').trim().length > 0 &&
      (this.relationVatNumber ?? '').trim().length > 0 &&
      (this.relationAddressStreet ?? '').trim().length > 0 &&
      (this.relationAddressNumber ?? '').trim().length > 0 &&
      (this.relationAddressCity ?? '').trim().length > 0 &&
      (this.relationAddressZip ?? '').trim().length > 0 &&
      !!this.relationAddressCountryId
    );
  }

  get Status() {
    switch (this.status) {
      case ProductDeliveryStatusEnum.CONCEPT:
        return translate(marker('label.productDelivery.status.concept', 'Concept'));
      case ProductDeliveryStatusEnum.DELIVERED:
        return translate(marker('label.productDelivery.status.delivered', 'Geleverd'));
    }
  }

  get companyAddress(): Address {
    return new Address({
      id: null,
      street: this.companyAddressStreet,
      number: this.companyAddressNumber,
      city: this.companyAddressCity,
      zip: this.companyAddressZip,
      county: this.companyAddressCounty,
      country_id: this.companyAddressCountryId,
      country: this.companyAddressCountry,
      default: null,
      subscriber_id: this.subscriberId,
    });
  }

  get customerAddress(): Address {
    return new Address({
      id: null,
      street: this.relationAddressStreet,
      number: this.relationAddressNumber,
      city: this.relationAddressCity,
      zip: this.relationAddressZip,
      county: this.relationAddressCounty,
      country_id: this.relationAddressCountryId,
      country: this.relationAddressCountry,
      default: null,
      subscriber_id: this.subscriberId,
    });
  }

  canConvertToSalesInvoice(): boolean {
    return !!this.sublines.find((subline) => !subline.salesInvoiceId);
  }

  constructor(delivery: IProductDelivery = null) {
    if (delivery === null) {
      return;
    }

    constructFromInterface(this, delivery);

    if (delivery.date) {this._date = GetDateScalar(delivery.date);}
    if (delivery.created) {this._created = GetDateTimeScalar(delivery.created);}
    if (delivery.updated) {this._updated = GetDateTimeScalar(delivery.updated);}
    if (delivery.companyAddressCountryId) {
      this.companyAddressCountry = new Country(countries.find(x => x.id == delivery.companyAddressCountryId));
    }
    if (delivery.company) {
      this.company = new Company(delivery.company);
    }
    if (delivery.relationAddressCountryId) {
      this.relationAddressCountry = new Country(countries.find(x => x.id == delivery.relationAddressCountryId));
    }
    if (delivery.relation) {
      this.relation = new Relation(delivery.relation);
    }

    if (delivery.sublines) {
      this.sublines = delivery.sublines.map((subline) => new ProductDeliverySubline(subline));
    }
    if (delivery.documents) {
      this.documents = delivery.documents.map((document) => new Document(document));
    }
  }

  public static new(): ProductDelivery {
    return new ProductDelivery({
      id: null,
      subscriberId: null,
      number: null,
      Number: null,
      date: SetDateScalar(new Date()),
      companyId: null,
      companyName: null,
      companyVatNumber: null,
      companyAddressStreet: null,
      companyAddressNumber: null,
      companyAddressCity: null,
      companyAddressZip: null,
      companyAddressCounty: null,
      companyAddressCountryId: null,
      companyAddressCountry: null,
      company: null,
      relationId: null,
      relationName: null,
      relationVatNumber: null,
      relationAddressStreet: null,
      relationAddressNumber: null,
      relationAddressCity: null,
      relationAddressZip: null,
      relationAddressCounty: null,
      relationAddressCountryId: null,
      relationAddressCountry: null,
      relation: null,
      status: ProductDeliveryStatusEnum.CONCEPT,
      note: null,
      created: null,
      updated: null,
      relationReference: null,
      documents: [],
      sublines: [],
    });
  }

  public convertToInput(): IProductDeliveryInput {
    return {
      id: +this.id,
      date: SetDateScalar(this.date ? moment(this.date)
        .toDate() : null),
      companyId: +this.companyId,
      companyName: this.companyName,
      companyVatNumber: this.companyVatNumber,
      companyAddressStreet: this.companyAddressStreet,
      companyAddressNumber: this.companyAddressNumber,
      companyAddressCity: this.companyAddressCity,
      companyAddressZip: this.companyAddressZip,
      companyAddressCounty: this.companyAddressCounty,
      companyAddressCountryId: +this.companyAddressCountryId,
      relationId: +this.relationId,
      relationName: this.relationName,
      relationVatNumber: this.relationVatNumber,
      relationAddressStreet: this.relationAddressStreet,
      relationAddressNumber: this.relationAddressNumber,
      relationAddressCity: this.relationAddressCity,
      relationAddressZip: this.relationAddressZip,
      relationAddressCounty: this.relationAddressCounty,
      relationAddressCountryId: +this.relationAddressCountryId,
      note: this.note,
      relationReference: (this.relationReference ?? '').trim().length > 0 ? '' + this.relationReference : null,
    };
  }

  public createMutationInput(sublines: MutationInputObject<ProductDeliverySubline>, originalProductDelivery: ProductDelivery = null): IProductDeliveryMutationInput {
    let mutationInput: IProductDeliveryMutationInput = {id: this.id, productDelivery: null, sublines: null, documents: null};
    mutationInput.productDelivery = _.isEqual(this.convertToInput(), originalProductDelivery?.convertToInput()) ? null : this.convertToInput(); /* Check if reception is updated */

    /* Set sublines */
    mutationInput.sublines = {
      deletes: sublines.deletes.map((x) => Number(x)),
      updates: sublines.updates.map((x) => {
        return {
          id: x.id,
          subline: x.convertToInput(),
        } as IProductDeliverySublineMutationInput;
      }),
    };

    return mutationInput;
  }
}

export class ProductDeliverySubline implements IProductDeliverySubline {
  id: number = 0;
  subscriberId: number = 0;
  deliveryId: number = 0;
  salesInvoiceId: number = 0;
  salesInvoice: Invoice;
  articleId: number = 0;
  stockId: number = 0;
  stock: Stock;
  articleSku: string = '';
  articleName: string = '';
  article: Product;
  articleWarranty: number = 0;
  articleSerialNumber: string = '';
  price: number = 0;
  note: string = '';
  created: string = "";
  private _created: Date;

  get Created() {return this._created;}

  set Created(date: Date) {
    this._created = date;
    this.created = SetDateTimeScalar(date);
  }

  updated: string = "";
  private _updated: Date;

  get Updated() {return this._updated;}

  set Updated(date: Date) {
    this._updated = date;
    this.updated = SetDateTimeScalar(date);
  }

  order: number = 0;
  unit: string = '';

  get Valid(): boolean {
    return (this.articleName ?? '').trim().length > 0 && this.price !== undefined && this.price !== null;
  }

  constructor(subline: IProductDeliverySubline = null) {
    if (subline === null) {
      return;
    }

    constructFromInterface(this, subline);

    if (subline.created) {this._created = GetDateTimeScalar(subline.created);}
    if (subline.updated) {this._updated = GetDateTimeScalar(subline.updated);}
    if (subline.salesInvoice) {
      this.salesInvoice = new Invoice(subline.salesInvoice);
    }
    if (subline.stock) {
      this.stock = new Stock(subline.stock);
    }
    if (subline.article) {
      this.article = new Product(subline.article);
    }
  }

  public static new(): ProductDeliverySubline {
    return new ProductDeliverySubline({
      id: null,
      subscriberId: null,
      deliveryId: null,
      salesInvoiceId: null,
      salesInvoice: null,
      articleId: null,
      stockId: null,
      stock: null,
      articleSku: null,
      articleName: null,
      article: null,
      articleWarranty: null,
      articleSerialNumber: null,
      price: 0,
      note: null,
      created: null,
      updated: null,
      order: null,
      unit: null,
    });
  }

  public convertToInput(): IProductDeliverySublineInput {
    return {
      id: +this.id,
      salesInvoiceId: +this.salesInvoiceId,
      articleId: +this.articleId,
      stockId: +this.stockId,
      articleSku: this.articleSku,
      articleName: this.articleName,
      articleWarranty: +this.articleWarranty,
      articleSerialNumber: this.articleSerialNumber,
      price: +this.price,
      note: this.note,
      order: +this.order,
      unit: (this.unit ?? '').trim().length > 0 ? '' + this.unit : null,
    };
  }
}

export enum ProductDeliveryStatusEnum {
  CONCEPT = 1,
  DELIVERED = 2,
}
