import _ from 'lodash';
import moment from 'moment-timezone';
import {PurchaseInvoice, PurchaseInvoiceSubline} from '../document/purchase-invoice';
import {constructFromInterface} from 'app/core/logic/map.logic';
import {IWriteOff, IWriteOffInput, IWriteOffMutationInput, IWriteOffSubline} from "../../../core/graphql/generated/types";
import {GetDateScalar, GetDateTimeScalar, SetDateScalar, SetDateTimeScalar} from "../../../core/logic/date-scalars";


export class WriteOff implements IWriteOff {
  id: number = 0;
  subscriberId: number = 0;
  number: number = 0;
  Number: string = '';
  name: string = '';
  description: string = '';
  date: string = "";
  private _date: Date;

  get Date() {return this._date;}

  set Date(date: Date) {
    this._date = date;
    this.date = SetDateScalar(date);
  }

  period: number = 0;
  purchaseprice: number = 0;
  percentage: number = 0;
  value: number = 0;
  type: WriteOffTypeEnum = WriteOffTypeEnum.LINEAR;
  prorata: boolean = false;
  status: WriteOffStatusEnum = WriteOffStatusEnum.CONCEPT;
  purchaseInvoiceId: number = 0;
  purchaseInvoice: PurchaseInvoice;
  purchaseInvoiceSublineId: number = 0;
  purchaseInvoiceSubline: PurchaseInvoiceSubline;
  created: string = "";

  private _created: Date;

  get Created() {return this._created;}

  set Created(date: Date) {
    this._created = date;
    this.created = SetDateTimeScalar(date);
  }

  updated: string = "";
  private _updated: Date;

  get Updated() {return this._updated;}

  set Updated(date: Date) {
    this._updated = date;
    this.updated = SetDateTimeScalar(date);
  }

  sublines: WriteOffSubline[] = [];

  get validation(): boolean {
    return (
      !!this.name &&
      this.name.trim().length > 0 &&
      !!this.date &&
      moment(this.date)
        .isValid() &&
      !!this.period &&
      this.period > 0 &&
      this.purchaseprice !== null &&
      this.percentage !== null &&
      this.value !== null
    );
  }

  get purchaseInvoiceInformation() {
    return `${this.purchaseInvoice.number} | ${this.purchaseInvoice.relationName} | ${this.purchaseInvoiceSubline.articleName}`;
  }

  constructor(writeOff: IWriteOff = null) {
    if (writeOff === null) {
      return;
    }

    constructFromInterface(this, writeOff);

    if (writeOff.date) {this._date = GetDateScalar(writeOff.date);}
    if (writeOff.created) {this._created = GetDateTimeScalar(writeOff.created);}
    if (writeOff.updated) {this._updated = GetDateTimeScalar(writeOff.updated);}
    if (writeOff.purchaseInvoice) {
      this.purchaseInvoice = new PurchaseInvoice(writeOff.purchaseInvoice);
    }
    if (writeOff.purchaseInvoiceSubline) {
      this.purchaseInvoiceSubline = new PurchaseInvoiceSubline(writeOff.purchaseInvoiceSubline);
    }

    if (writeOff.sublines && writeOff.sublines.length > 0) {
      this.sublines = writeOff.sublines.map((subline) => new WriteOffSubline(subline));
    }
  }

  public static new(): WriteOff {
    return new WriteOff({
      id: null,
      number: null,
      Number: null,
      subscriberId: null,
      name: null,
      description: null,
      date: null,
      period: null,
      percentage: 100,
      purchaseprice: null,
      value: null,
      type: WriteOffTypeEnum.LINEAR,
      status: WriteOffStatusEnum.CONCEPT,
      prorata: true,
      purchaseInvoiceId: null,
      purchaseInvoice: null,
      purchaseInvoiceSublineId: null,
      purchaseInvoiceSubline: null,
      created: null,
      updated: null,
      sublines: [],
    });
  }

  public convertToInput(): IWriteOffInput {
    return {
      id: +this.id,
      name: this.name,
      description: this.description,
      date: SetDateScalar(this.date ? moment(this.date)
        .toDate() : null),
      period: +this.period,
      purchaseprice: +this.purchaseprice,
      percentage: +this.percentage,
      value: +this.value,
      prorata: !!this.prorata,
      type: +this.type,
      purchaseInvoiceId: +this.purchaseInvoiceId,
      purchaseInvoiceSublineId: +this.purchaseInvoiceSublineId,
    };
  }

  public createMutationInput(originalWriteOff: WriteOff = null): IWriteOffMutationInput {
    let mutationInput: IWriteOffMutationInput = {id: this.id, writeoff: null};
    mutationInput.writeoff = _.isEqual(this.convertToInput(), originalWriteOff?.convertToInput()) ? null : this.convertToInput();

    return mutationInput;
  }
}

export class WriteOffSubline implements IWriteOffSubline {
  id: number = 0;
  subscriberId: number = 0;
  writeOffId: number = 0;
  year: number = 0;
  month: number = 0;
  day: number = 0;
  value: number = 0;
  rest: number = 0;
  created: string = "";

  private _created: Date;

  get Created() {return this._created;}

  set Created(date: Date) {
    this._created = date;
    this.created = SetDateTimeScalar(date);
  }

  updated: string = "";
  private _updated: Date;

  get Updated() {return this._updated;}

  set Updated(date: Date) {
    this._updated = date;
    this.updated = SetDateTimeScalar(date);
  }

  constructor(subline: IWriteOffSubline = null) {
    if (subline === null) {
      return;
    }

    constructFromInterface(this, subline);

    if (subline.created) {this._created = GetDateTimeScalar(subline.created);}
    if (subline.updated) {this._updated = GetDateTimeScalar(subline.updated);}
  }
}

export enum WriteOffTypeEnum {
  LINEAR = 1,
  DEGRESSIVE = 2,
}

export enum WriteOffStatusEnum {
  CONCEPT = 1,
  OPEN = 2,
  COMPLETED = 3,
}
