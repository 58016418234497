import {VatCode} from "../models/system/vatCode";

export const Vats = [
  new VatCode({
    code: "BE-VAT-21",
    percentage: 21,
    intra: false,
    countryId: 56,
    obsolete: false,
  }),
  new VatCode({
    code: "BE-VAT-12",
    percentage: 12,
    intra: false,
    countryId: 56,
    obsolete: false,
  }),
  new VatCode({
    code: "BE-VAT-6",
    percentage: 6,
    intra: false,
    countryId: 56,
    obsolete: false,
  }),
  new VatCode({
    code: "BE-REN-6",
    percentage: 6,
    intra: false,
    countryId: 56,
    obsolete: false,
  }),
  new VatCode({
    code: "BE-VAT-0",
    percentage: 0,
    intra: false,
    countryId: 56,
    obsolete: false,
  }),
  new VatCode({
    code: "BE-INTRA-0",
    percentage: 0,
    intra: true,
    countryId: 56,
    obsolete: false,
  }),
  new VatCode({
    code: "BE-MC-0",
    percentage: 0,
    intra: false,
    countryId: 56,
    obsolete: false,
  }),
];
