import {constructFromInterface} from 'app/core/logic/map.logic';
import {IUserMessage} from "../../../core/graphql/generated/types";
import {GetDateTimeScalar, SetDateTimeScalar} from "../../../core/logic/date-scalars";


export class UserMessage implements IUserMessage {
  id: number = 0;
  subscriber_id: number = 0;
  user_id: number = 0;
  message: string = '';
  read: boolean = false;
  route: string = '';
  permissions: string = '';
  created: string = "";
  private _created: Date;

  get Created() {return this._created;}

  set Created(date: Date) {
    this._created = date;
    this.created = SetDateTimeScalar(date);
  }

  updated: string = "";
  private _updated: Date;

  get Updated() {return this._updated;}

  set Updated(date: Date) {
    this._updated = date;
    this.updated = SetDateTimeScalar(date);
  }

  type: UserMessageType = UserMessageType.INFORMATION;
  urgency: UserMessageUrgency = UserMessageUrgency.NORMAL;

  readLoading = false;

  constructor(message: IUserMessage) {
    constructFromInterface(this, message);

    if (message.created) {this._created = GetDateTimeScalar(message.created);}
    if (message.updated) {this._updated = GetDateTimeScalar(message.updated);}
  }

  static new(): UserMessage {
    return new UserMessage({
      id: null,
      subscriber_id: null,
      user_id: null,
      message: null,
      read: null,
      route: null,
      permissions: null,
      created: null,
      updated: null,
      type: UserMessageType.INFORMATION,
      urgency: UserMessageUrgency.NORMAL,
    });
  }
}

export enum UserMessageUrgency {
  NORMAL = 1,
  SLIGHTLY = 2,
  EXTREME = 3,
}

export enum UserMessageType {
  INFORMATION = 1,
  ERROR = 2,
  COMPLETED = 3,
  WARNING = 4,
}
