import {translate} from '@jsverse/transloco';
import {marker} from '@nyffels/transloco-keys-manager/marker';
import {constructFromInterface} from 'app/core/logic/map.logic';
import {IPeppolIntegration, IPeppolRegistration} from "../../../core/graphql/generated/types";


export class PeppolIntegration implements IPeppolIntegration {
  id: number = 0;
  integrationIbanityEnabled: boolean = false;
  integrationIbanityContact: string = '';
  integrationIbanityPassport: string = '';
  integrationIbanityHomepage: string = '';
  integrationIbanityPhone: string = '';
  integrationIbanityReceiver: boolean = false;
  integrationIbanityKycCompleted: boolean = false;
  status: string = '';
  registrations: PeppolIntegrationRegistration[] = [];

  get Status() {
    switch (this.status) {
      case "created":
      case "approved":
        return translate(marker('label.peppol.status.createdAndApproved', "In afwachting"));
      case "rejected":
        return translate(marker('label.peppol.status.rejected', "Afgewezen"));
      case "onboarded":
        return translate(marker('label.peppol.status.onboarded', "Actief"));
      case "offboarded":
        return translate(marker('label.peppol.status.offboarded', "Afgemeld"));
      default:
        return translate(marker('label.peppol.status.unknown', 'Onbekend'));
    }
  }

  get IsValid() {
    return this.integrationIbanityEnabled && this.status == "onboarded";
  }

  constructor(peppol: IPeppolIntegration = null) {
    if (peppol === null) {
      return;
    }

    constructFromInterface(this, peppol);
    if (peppol.registrations) {
      this.registrations = peppol.registrations.map((r) => new PeppolIntegrationRegistration(r));
    }
  }
}

export class PeppolIntegrationRegistration implements IPeppolRegistration {
  reason: string = '';
  status: string = '';
  type: string = '';
  value: string = '';
  accessPoints: string[] = [];

  get Type() {
    switch (this.type) {
      case 'vat-number':
        return translate(marker('label.peppolIntegrationRegistration.type.vatNumber', 'Europees BTW nummer'));
      case 'enterprise-number':
        return translate(marker('label.peppolIntegrationRegistration.type.enterpriseNumber', 'Ondernemingsnummer'));
      case 'kvk-number':
        return translate(marker('label.peppolIntegrationRegistration.type.kvkNumber', 'Kvk nummer'));
      case 'national-identification-number':
        return translate(marker('label.peppolIntegrationRegistration.type.nationalIdentificationNumber', 'Nationaal herkenningsnummer'));
    }
  }

  get Status() {
    switch (this.status) {
      case 'registration-in-progress':
        return translate(marker('label.peppolIntegrationRegistration.status.registratioInProgess', 'Aangevraagd'));
      case 'registered':
        return translate(marker('label.peppolIntegrationRegistration.status.registered', 'Actief'));
      case 'registration-failed':
        switch (this.reason) {
          case 'already-registered':
            return translate(marker('label.peppolIntegrationRegistration.status.registrationFailed.alreadyRegistered', "Reeds geregistreerd op '{{accesspoints}}'"), {accesspoints: this.accessPoints.join(', ')});
          case 'blocked':
            return translate(marker('label.peppolIntegrationRegistration.status.registrationFailed.blocked', 'Geblokkeerd'));
          case 'general-error':
            return translate(marker('label.peppolIntegrationRegistration.status.registrationFailed.generalError', 'Algemene fout'));
          default:
            return translate(marker('label.peppolIntegrationRegistration.status.registrationFailed.default', 'Gefaald'));
        }
      case 'deregistered':
        return translate(marker('label.peppolIntegrationRegistration.status.deregistered', 'Gedeactiveerd'));
    }
  }

  get StatusColor() {
    switch (this.status) {
      case 'registration-in-progress':
        return 'primary';
      case 'registered':
        return 'success';
      case 'registration-failed':
        return 'danger';
      case 'deregistered':
        return 'warn';
    }
  }

  constructor(registation: IPeppolRegistration) {
    constructFromInterface(this, registation);

    if (registation.accessPoints) {
      this.accessPoints = registation.accessPoints;
    }
  }
}
