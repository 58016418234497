import {VatService} from "../../../core/services/vat.service";

export interface IVatCode {
  code: string;
  percentage: number;
  intra: boolean;
  countryId: number;
  obsolete: boolean;
}

export class VatCode implements IVatCode {
  code: string;
  percentage: number;
  intra: boolean;
  countryId: number;
  obsolete: boolean;

  constructor(vat: IVatCode) {
    this.code = vat.code;
    this.percentage = vat.percentage;
    this.intra = vat.intra;
    this.countryId = vat.countryId;
    this.obsolete = vat.obsolete;
  }

  get label() {
    return VatService.getLabel(this.code);
  }
}
