import {constructFromInterface} from 'app/core/logic/map.logic';
import {User} from '../entity/user';
import {translate} from "@jsverse/transloco";
import {marker} from "@nyffels/transloco-keys-manager/marker";
import {GetDateTimeScalar, SetDateTimeScalar} from "../../../core/logic/date-scalars";
import {IDeliveredDocument, IDeliveredDocumentDocumentType, IDeliveredDocumentSource, IDeliveredDocumentStatus, IDeliveredDocumentType} from "../../../core/graphql/generated/types";

export class DeliveredDocument implements IDeliveredDocument {
  id: number = 0;
  subscriberId: number = 0;
  userId: number = 0;
  user: User;
  fileName: string = '';
  type: IDeliveredDocumentType = IDeliveredDocumentType.Purchaseinvoice;
  documentType: IDeliveredDocumentDocumentType = IDeliveredDocumentDocumentType.Pdf;
  status: IDeliveredDocumentStatus = IDeliveredDocumentStatus.Qeue;
  result: string = '';
  completed: string = "";
  private _completed: Date;

  get Completed() {return this._completed;}

  set Completed(date: Date) {
    this._completed = date;
    this.completed = SetDateTimeScalar(date);
  }

  created: string = "";

  private _created: Date;

  get Created() {return this._created;}

  set Created(date: Date) {
    this._created = date;
    this.created = SetDateTimeScalar(date);
  }

  source: IDeliveredDocumentSource = IDeliveredDocumentSource.System;
  raw: string = "";

  get Result() {
    try {
      return JSON.parse(this.result?.replace(/(?:\r\n|\r|\n)/g, "\\n"));
    } catch (ex) {
      console.error(ex);
      return this.result;
    }
  }

  get Status(): string {
    switch (this.status) {
      case IDeliveredDocumentStatus.Qeue:
        return translate(marker("status.ocr.qeue", "In wacht"));
      case IDeliveredDocumentStatus.Processing:
      case IDeliveredDocumentStatus.Processingexternal:
        return translate(marker("status.ocr.processing", "Verwerken"));
      case IDeliveredDocumentStatus.Completed:
        return translate(marker("status.ocr.completed", "Uitgelezen"));
      case IDeliveredDocumentStatus.Failed:
        return translate(marker("status.ocr.failed", "Mislukt"));
      case IDeliveredDocumentStatus.Finished:
        return translate(marker("status.ocr.finished", "Afgerond"));
      default:
        return translate(marker("status.ocr.qeue", "In wacht"));
    }
  }

  get Type() {
    switch (this.type) {
      case IDeliveredDocumentType.Purchaseinvoice:
        return translate(marker('type.ocr.purchaseinvoice', 'Aankoopfactuur'))
      case IDeliveredDocumentType.Purchasecreditnote:
        return translate(marker('type.ocr.purchasecreditnote', 'Aankoop creditnota'))
    }
  }

  get DocumentType() {
    switch (this.documentType) {
      case IDeliveredDocumentDocumentType.Pdf:
        return translate(marker('type.document.pdf', "Pdf"));
      case IDeliveredDocumentDocumentType.Ubl:
        return translate(marker('type.document.ubl', 'Ubl'));
    }
  }

  constructor(deliveredDocument: IDeliveredDocument = null) {
    if (deliveredDocument === null) {
      return null;
    }

    constructFromInterface(this, deliveredDocument);

    if (deliveredDocument.completed) {this._completed = GetDateTimeScalar(deliveredDocument.completed);}
    if (deliveredDocument.created) {this._created = GetDateTimeScalar(deliveredDocument.created);}
    if (deliveredDocument.user) {
      this.user = new User(deliveredDocument.user);
    }
  }
}
