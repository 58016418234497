import {constructFromInterface} from 'app/core/logic/map.logic';
import _ from 'lodash';
import {IAccountNumber, IAccountNumberCategory, IAccountNumberClass, IAccountNumberInput, IAccountNumberMutationInput} from "../../../core/graphql/generated/types";
import {GetDateTimeScalar, SetDateTimeScalar} from "../../../core/logic/date-scalars";

export class AccountNumberClass implements IAccountNumberClass {
  number: string = '';
  description: string = '';

  constructor(accountNumberClass: IAccountNumberClass) {
    if (accountNumberClass === null) {
      return;
    }

    constructFromInterface(this, accountNumberClass);
  }

  get fullName() {
    return `${this.number}: ${this.description}`;
  }
}

export class AccountNumberCategory implements IAccountNumberCategory {
  class: string = '';
  number: string = '';
  description: string = '';

  constructor(accountNumberCategory: IAccountNumberCategory) {
    if (accountNumberCategory === null) {
      return;
    }

    constructFromInterface(this, accountNumberCategory);
  }

  get fullName() {
    return `${this.number}: ${this.description}`;
  }
}

export class AccountNumber implements IAccountNumber {
  id: number = 0;
  class: string = '';
  classObject: AccountNumberClass;
  category: string = '';
  categoryObject: AccountNumberCategory;
  number: string = '';
  fullNumber: string = '';
  subscriberId: number = 0;
  description: string = '';
  archived: boolean = false;
  created: string = "";

  private _created: Date;

  get Created() {return this._created;}

  set Created(date: Date) {
    this._created = date;
    this.created = SetDateTimeScalar(date);
  }

  updated: string = "";
  private _updated: Date;

  get Updated() {return this._updated;}

  set Updated(date: Date) {
    this._updated = date;
    this.updated = SetDateTimeScalar(date);
  }

  vatRecupPercentage: number = 0;
  fiscalProfessionalPercentage: number = 0;
  fiscalRecupPercentage: number = 0;
  alwaysMarkAsACost: boolean = false;

  get FullName() {
    return `${this.description} (${this.fullNumber})`
  }

  get Validation(): boolean {
    return (
      ('' + this.class).trim().length > 0 &&
      ('' + this.category).trim().length > 0 &&
      ('' + this.number).trim().length > 0 &&
      this.vatRecupPercentage !== null &&
      this.vatRecupPercentage !== undefined &&
      this.fiscalProfessionalPercentage !== null &&
      this.fiscalProfessionalPercentage !== undefined &&
      this.fiscalRecupPercentage !== null &&
      this.fiscalRecupPercentage !== undefined &&
      (this.description ?? '').trim().length > 0
    )
  }

  constructor(accountNumber: IAccountNumber = null) {
    if (accountNumber === null) {
      return;
    }

    constructFromInterface(this, accountNumber);

    if (accountNumber.created) {this._created = GetDateTimeScalar(accountNumber.created);}
    if (accountNumber.updated) {this._updated = GetDateTimeScalar(accountNumber.updated);}
    if (accountNumber.classObject) {
      this.classObject = new AccountNumberClass(accountNumber.classObject);
    }
    if (accountNumber.categoryObject) {
      this.categoryObject = new AccountNumberCategory(accountNumber.categoryObject);
    }
  }

  public static new(): AccountNumber {
    return new AccountNumber({
      id: null,
      class: null,
      classObject: null,
      category: null,
      categoryObject: null,
      number: null,
      fullNumber: null,
      subscriberId: null,
      description: null,
      archived: false,
      created: null,
      updated: null,
      vatRecupPercentage: 100,
      fiscalProfessionalPercentage: 100,
      fiscalRecupPercentage: 100,
      alwaysMarkAsACost: false
    });
  }

  public convertToInput(): IAccountNumberInput {
    return {
      id: +this.id,
      class: '' + this.class,
      category: '' + this.category,
      number: '' + this.number,
      description: '' + this.description,
      archived: !!this.archived,
      vatRecupPercentage: +this.vatRecupPercentage,
      fiscalProfessionalPercentage: +this.fiscalProfessionalPercentage,
      fiscalRecupPercentage: +this.fiscalRecupPercentage,
      alwaysMarkAsACost: !!this.alwaysMarkAsACost
    };
  }

  public createMutationInput(originalAccountNumber: AccountNumber = null): IAccountNumberMutationInput {
    let mutationInput: IAccountNumberMutationInput = {id: null, accountNumber: null};
    mutationInput.accountNumber = _.isEqual(this, originalAccountNumber) ? null : this.convertToInput();
    if (this.id) {
      mutationInput.id = this.id;
    }

    return mutationInput;
  }

  public generateFullNumber(): void {
    if (!this.class || !this.category || !this.number) {
      this.fullNumber = null;
    } else {
      this.fullNumber = this.class + this.category + this.number;
    }
  }
}
