import {constructFromInterface} from "app/core/logic/map.logic";
import {Relation} from "../relation/relation.class";
import {IArticlePrice, IArticlePriceInput} from "../../../core/graphql/generated/types";
import {GetDateTimeScalar, SetDateTimeScalar} from "../../../core/logic/date-scalars";


export class ArticlePrice implements IArticlePrice {
  id: number = 0;
  subscriberId: number = 0;
  relationId: number = 0;
  relation: Relation;
  articleId: number = 0;
  price: number = 0;
  url: string = "";
  created: string = "";

  private _created: Date;

  get Created() {return this._created;}

  set Created(date: Date) {
    this._created = date;
    this.created = SetDateTimeScalar(date);
  }

  updated: string = "";
  private _updated: Date;

  get Updated() {return this._updated;}

  set Updated(date: Date) {
    this._updated = date;
    this.updated = SetDateTimeScalar(date);
  }

  minimumOrderQuantity: number = 0;

  constructor(price: IArticlePrice = null) {
    if (price === null) {
      return;
    }

    constructFromInterface(this, price);

    if (price.created) {this._created = GetDateTimeScalar(price.created);}
    if (price.updated) {this._updated = GetDateTimeScalar(price.updated);}
    if (price.relation) {
      this.relation = new Relation(price.relation);
    }
  }

  get Validation(): boolean {
    return this.relationId > 0;
  }

  convertToInput(): IArticlePriceInput {
    return {
      id: +this.id,
      relationId: +this.relationId,
      articleId: +this.articleId,
      price: this.price === null ? null : +this.price,
      url: this.url,
      minimumOrderQuantity: this.minimumOrderQuantity !== null ? +this.minimumOrderQuantity : null,
    };
  }

  public static new(): ArticlePrice {
    return new ArticlePrice({
      id: null,
      subscriberId: null,
      relationId: null,
      relation: null,
      articleId: null,
      price: null,
      url: null,
      created: null,
      updated: null,
      minimumOrderQuantity: 1,
    });
  }
}
