import moment from 'moment';
import {Product} from 'app/shared/models/article/product';
import {PurchaseInvoice} from './purchase-invoice';
import {Stock} from '../article/stock';
import {Address} from '../relation/address';
import {Relation} from '../relation/relation.class';
import {Country} from '../general/country';
import {Company} from '../entity/company';
import {Document} from '../general/document';
import {constructFromInterface} from 'app/core/logic/map.logic';
import {IProductReception, IProductReceptionInput, IProductReceptionSubline, IProductReceptionSublineInput} from "../../../core/graphql/generated/types";
import {GetDateScalar, GetDateTimeScalar, SetDateScalar, SetDateTimeScalar} from "../../../core/logic/date-scalars";
import {countries} from "../../data/country";
import {translate} from "@jsverse/transloco";
import {marker} from "@nyffels/transloco-keys-manager/marker";

export class ProductReception implements IProductReception {
  id: number = 0;
  subscriberId: number = 0;
  number: number = 0;
  Number: string = '';
  date: string = '';
  private _date: Date;

  get Date() {return this._date;}

  set Date(date: Date) {
    this._date = date;
    this.date = SetDateScalar(date);
  }

  companyId: number = 0;
  companyName: string = '';
  companyVatNumber: string = '';
  companyAddressStreet: string = '';
  companyAddressNumber: string = '';
  companyAddressCity: string = '';
  companyAddressZip: string = '';
  companyAddressCounty: string = '';
  companyAddressCountryId: number = 0;
  companyAddressCountry: Country;

  get companyAddress(): Address {
    return new Address({
      id: null,
      street: this.companyAddressStreet,
      number: this.companyAddressNumber,
      city: this.companyAddressCity,
      zip: this.companyAddressZip,
      county: this.companyAddressCounty,
      country_id: this.companyAddressCountryId,
      country: this.companyAddressCountry,
      default: null,
      subscriber_id: this.subscriberId,
    });
  }

  company: Company;
  relationId: number = 0;
  relationName: string = '';
  relationVatNumber: string = '';
  relationAddressStreet: string = '';
  relationAddressNumber: string = '';
  relationAddressCity: string = '';
  relationAddressZip: string = '';
  relationAddressCounty: string = '';
  relationAddressCountryId: number = 0;
  relationAddressCountry: Country;

  get supplierAddress(): Address {
    return new Address({
      id: null,
      street: this.relationAddressStreet,
      number: this.relationAddressNumber,
      city: this.relationAddressCity,
      zip: this.relationAddressZip,
      county: this.relationAddressCounty,
      country_id: this.relationAddressCountryId,
      country: this.relationAddressCountry,
      default: null,
      subscriber_id: this.subscriberId,
    });
  }

  relation: Relation;
  status: ProductReceptionStatusEnum = ProductReceptionStatusEnum.CONCEPT;

  get Status() {
    switch (this.status) {
      case ProductReceptionStatusEnum.CONCEPT:
        return translate(marker('label.productReception.status.concept', 'Concept'));
      case ProductReceptionStatusEnum.RECEIVED:
        return translate(marker('label.productReception.status.received', 'Ontvangen'));
    }
  }

  note: string = '';
  created: string = "";
  private _created: Date;

  get Created() {return this._created;}

  set Created(date: Date) {
    this._created = date;
    this.created = SetDateTimeScalar(date);
  }

  updated: string = "";
  private _updated: Date;

  get Updated() {return this._updated;}

  set Updated(date: Date) {
    this._updated = date;
    this.updated = SetDateTimeScalar(date);
  }

  sublines: ProductReceptionSubline[] = [];
  documents: Document[] = [];

  get Valid(): boolean {
    return (
      moment(this.date)
        .isValid() &&
      (this.companyName ?? '').trim().length > 0 &&
      (this.companyVatNumber ?? '').trim().length > 0 &&
      (this.companyAddressStreet ?? '').trim().length > 0 &&
      (this.companyAddressNumber ?? '').trim().length > 0 &&
      (this.companyAddressCity ?? '').trim().length > 0 &&
      (this.companyAddressZip ?? '').trim().length > 0 &&
      !!this.companyAddressCountryId &&
      (this.relationName ?? '').trim().length > 0
    );
  }

  canConvertToPurchaseInvoice(): boolean {
    return !!this.sublines.find((subline) => !subline.purchaseInvoiceId);
  }

  constructor(reception: IProductReception = null) {
    if (reception === null) {
      return;
    }

    constructFromInterface(this, reception);

    if (reception.date) {this._date = GetDateScalar(reception.date);}
    if (reception.created) {this._created = GetDateTimeScalar(reception.created);}
    if (reception.updated) {this._updated = GetDateTimeScalar(reception.updated);}
    if (reception.companyAddressCountryId) {
      this.companyAddressCountry = new Country(countries.find(x => x.id == reception.companyAddressCountryId));
    }
    if (reception.company) {
      this.company = new Company(reception.company);
    }
    if (reception.relationAddressCountryId) {
      this.relationAddressCountry = new Country(countries.find(x => x.id == reception.relationAddressCountryId));
    }
    if (reception.relation) {
      this.relation = new Relation(reception.relation);
    }
    if (reception.sublines) {
      this.sublines = reception.sublines.map((subline) => new ProductReceptionSubline(subline));
    }
    if (reception.documents) {
      this.documents = reception.documents.map((document) => new Document(document));
    }
  }

  public static new(): ProductReception {
    return new ProductReception({
      id: null,
      subscriberId: null,
      number: null,
      Number: null,
      date: SetDateScalar(new Date()),
      companyId: null,
      companyName: null,
      companyVatNumber: null,
      companyAddressStreet: null,
      companyAddressNumber: null,
      companyAddressCity: null,
      companyAddressZip: null,
      companyAddressCounty: null,
      companyAddressCountryId: null,
      companyAddressCountry: null,
      company: null,
      relationId: null,
      relationName: null,
      relationVatNumber: null,
      relationAddressStreet: null,
      relationAddressNumber: null,
      relationAddressCity: null,
      relationAddressZip: null,
      relationAddressCounty: null,
      relationAddressCountryId: null,
      relationAddressCountry: null,
      relation: null,
      status: ProductReceptionStatusEnum.CONCEPT,
      note: null,
      created: null,
      updated: null,
      documents: [],
      sublines: [],
    });
  }

  public convertToInput(): IProductReceptionInput {
    return {
      id: +this.id,
      date: SetDateScalar(this.date ? moment(this.date)
        .toDate() : null),
      companyId: +this.companyId,
      companyName: this.companyName,
      companyVatNumber: this.companyVatNumber,
      companyAddressStreet: this.companyAddressStreet,
      companyAddressNumber: this.companyAddressNumber,
      companyAddressCity: this.companyAddressCity,
      companyAddressZip: this.companyAddressZip,
      companyAddressCounty: this.companyAddressCounty,
      companyAddressCountryId: +this.companyAddressCountryId,
      relationId: +this.relationId,
      relationName: this.relationName,
      relationVatNumber: this.relationVatNumber,
      relationAddressStreet: this.relationAddressStreet,
      relationAddressNumber: this.relationAddressNumber,
      relationAddressCity: this.relationAddressCity,
      relationAddressZip: this.relationAddressZip,
      relationAddressCounty: this.relationAddressCounty,
      relationAddressCountryId: +this.relationAddressCountryId,
      note: this.note,
    };
  }
}

export class ProductReceptionSubline implements IProductReceptionSubline {
  id: number = 0;
  subscriberId: number = 0;
  receptionId: number = 0;
  purchaseInvoiceId: number = 0;
  purchaseInvoice: PurchaseInvoice;
  articleId: number = 0;
  stockId: number = 0;
  stock: Stock;
  articleSku: string = '';
  articleName: string = '';
  article: Product;
  articleSerialNumber: string = '';
  articleWarranty: number = 0;
  price: number = 0;
  note: string = '';
  created: string = "";
  private _created: Date;

  get Created() {return this._created;}

  set Created(date: Date) {
    this._created = date;
    this.created = SetDateTimeScalar(date);
  }

  updated: string = "";
  private _updated: Date;

  get Updated() {return this._updated;}

  set Updated(date: Date) {
    this._updated = date;
    this.updated = SetDateTimeScalar(date);
  }

  order: number = 0;
  unit: string = '';

  get Valid(): boolean {
    return (this.articleName ?? '').trim().length > 0 && this.price !== null && this.price !== undefined;
  }

  constructor(subline: IProductReceptionSubline) {
    constructFromInterface(this, subline);

    if (subline.created) {this._created = GetDateTimeScalar(subline.created);}
    if (subline.updated) {this._updated = GetDateTimeScalar(subline.updated);}
    if (subline.purchaseInvoice) {
      this.purchaseInvoice = new PurchaseInvoice(subline.purchaseInvoice);
    }
    if (subline.stock) {
      this.stock = new Stock(subline.stock);
    }
    if (subline.article) {
      this.article = new Product(subline.article);
    }
  }

  public static new(): ProductReceptionSubline {
    return new ProductReceptionSubline({
      id: null,
      subscriberId: null,
      receptionId: null,
      purchaseInvoiceId: null,
      purchaseInvoice: null,
      articleId: null,
      articleSku: null,
      articleName: null,
      article: null,
      articleSerialNumber: null,
      articleWarranty: null,
      price: 0,
      note: null,
      created: null,
      updated: null,
      order: null,
      stock: null,
      stockId: null,
      unit: null,
    });
  }

  public convertToInput(): IProductReceptionSublineInput {
    return {
      id: +this.id,
      purchaseInvoiceId: +this.purchaseInvoiceId,
      articleId: +this.articleId,
      articleSku: this.articleSku,
      articleName: this.articleName,
      price: +this.price,
      articleSerialNumber: this.articleSerialNumber,
      articleWarranty: +this.articleWarranty,
      note: this.note,
      order: +this.order,
      unit: (this.unit ?? '').trim().length > 0 ? '' + this.unit : null,
    };
  }
}

export enum ProductReceptionStatusEnum {
  CONCEPT = 1,
  RECEIVED = 2,
}
