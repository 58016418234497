import {constructFromInterface} from 'app/core/logic/map.logic';
import {IYukiIntegration} from "../../../core/graphql/generated/types";


export class YukiIntegration implements IYukiIntegration {
  id: number = 0;
  integrationYukiEnabled: boolean = false;
  integrationYukiApiKey: string = '';
  integrationYukiAdministrationId: string = '';

  constructor(yuki: IYukiIntegration = null) {
    if (yuki === null) {
      return;
    }

    constructFromInterface(this, yuki);
  }
}
