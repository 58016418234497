import {constructFromInterface} from 'app/core/logic/map.logic';
import _ from 'lodash';
import {IJournal, IJournalInput, IJournalMutationInput} from "../../../core/graphql/generated/types";
import {GetDateTimeScalar, SetDateTimeScalar} from "../../../core/logic/date-scalars";

export class Journal implements IJournal {
  id: number = 0;
  subscriberId: number = 0;
  type: JournalTypeEnum = JournalTypeEnum.SALES;
  invoicePrefix: string = '';
  invoiceSize: number = 0;
  creditnotePrefix: string = '';
  creditnoteSize: number = 0;
  number: string = '';
  externalInvoiceNumber: string = '';
  externalCreditnoteNumber: string = '';
  description: string = '';
  default: boolean = false;
  created: string = "";

  private _created: Date;

  get Created() {return this._created;}

  set Created(date: Date) {
    this._created = date;
    this.created = SetDateTimeScalar(date);
  }

  updated: string = "";
  private _updated: Date;

  get Updated() {return this._updated;}

  set Updated(date: Date) {
    this._updated = date;
    this.updated = SetDateTimeScalar(date);
  }

  archived: boolean = false;

  isValid(): boolean {
    return (this.number ?? '').trim().length > 0 && (this.type == JournalTypeEnum.SALES ? ((this.creditnoteSize ?? 0) > 0 && (this.invoiceSize ?? 0) > 0) : true);
  }

  constructor(journal: IJournal = null) {
    if (journal === null) {
      return;
    }

    constructFromInterface(this, journal);

    if (journal.created) {this._created = GetDateTimeScalar(journal.created);}
    if (journal.updated) {this._updated = GetDateTimeScalar(journal.updated);}
  }

  public static new(): Journal {
    return new Journal({
      id: null,
      subscriberId: null,
      type: null,
      invoicePrefix: null,
      invoiceSize: null,
      creditnotePrefix: null,
      creditnoteSize: null,
      number: null,
      externalInvoiceNumber: null,
      externalCreditnoteNumber: null,
      description: null,
      default: true,
      created: null,
      updated: null,
      archived: false,
    });
  }

  public convertToInput(): IJournalInput {
    return {
      id: +this.id,
      type: +this.type,
      invoicePrefix: this.invoicePrefix,
      invoiceSize: this.invoiceSize ? +this.invoiceSize : null,
      creditnotePrefix: this.creditnotePrefix,
      creditnoteSize: +this.creditnoteSize,
      number: this.number,
      externalInvoiceNumber: this.externalInvoiceNumber,
      externalCreditnoteNumber: this.externalCreditnoteNumber,
      description: this.description,
      default: !!this.default,
      archived: !!this.archived,
    };
  }

  public createMutationInput(originalJournal: Journal = null): IJournalMutationInput {
    let mutationInput: IJournalMutationInput = {id: this.id, journal: null};
    mutationInput.journal = _.isEqual(this.convertToInput(), originalJournal?.convertToInput()) ? null : this.convertToInput();

    return mutationInput;
  }
}

export enum JournalTypeEnum {
  SALES = 1,
  PURCHASE = 2,
}
