import {ArticlePrice} from './article-price';
import {ArticleCategory} from '../parameters/article-category';
import {constructFromInterface} from 'app/core/logic/map.logic';
import {IProduct, IProductInput} from "../../../core/graphql/generated/types";
import {GetDateTimeScalar, SetDateTimeScalar} from "../../../core/logic/date-scalars";


export class Product implements IProduct {
  id: number = 0;
  subscriber_id: number = 0;
  sku: string = '';
  ean: string = '';
  name: string = '';
  description: string = '';
  importDescriptionOnDocument: boolean = false;

  get FullName(): string {
    if (this.sku) {
      return `${this.name} (${this.sku})`;
    } else {
      return this.name;
    }
  }

  artcat_id: number = 0;
  category: ArticleCategory;
  salesprice: number = 0;
  created: string = "";

  private _created: Date;

  get Created() {return this._created;}

  set Created(date: Date) {
    this._created = date;
    this.created = SetDateTimeScalar(date);
  }

  updated: string = "";
  private _updated: Date;

  get Updated() {return this._updated;}

  set Updated(date: Date) {
    this._updated = date;
    this.updated = SetDateTimeScalar(date);
  }

  archived: boolean = false;
  defaultUnit: string = '';

  prices: ArticlePrice[] = [];
  inStockAmount: number = 0;

  constructor(product: IProduct = null) {
    if (product === null) {
      return;
    }

    constructFromInterface(this, product);

    if (product.created) {this._created = GetDateTimeScalar(product.created);}
    if (product.updated) {this._updated = GetDateTimeScalar(product.updated);}
    if (product.category) {
      this.category = new ArticleCategory(product.category);
    }

    if (product.prices) {
      this.prices = product.prices.map((x) => new ArticlePrice(x));
    }
  }

  get Validation(): boolean {
    return this.name && this.name.trim().length > 0;
  }

  autogenerateSku(): void {
    this.sku = this.name.toLowerCase()
      .replace(/ /g, '-');
  }

  convertToInput(): IProductInput {
    return {
      id: this.id,
      sku: this.sku,
      ean: this.ean,
      name: this.name,
      description: this.description,
      artcat_id: +this.artcat_id,
      salesprice: +this.salesprice,
      archived: this.archived,
      defaultUnit: this.defaultUnit,
      importDescriptionOnDocument: this.importDescriptionOnDocument,
    };
  }

  public static new(): Product {
    return new Product({
      id: null,
      subscriber_id: null,
      sku: null,
      ean: null,
      name: null,
      description: null,
      artcat_id: null,
      category: null,
      salesprice: null,
      created: null,
      updated: null,
      archived: false,
      prices: [],
      inStockAmount: 0,
      defaultUnit: null,
      importDescriptionOnDocument: false,
    });
  }
}
