import {User} from '../entity/user';
import {constructFromInterface} from 'app/core/logic/map.logic';
import {IPeppol} from "../../../core/graphql/generated/types";
import {GetDateTimeScalar, SetDateTimeScalar} from 'app/core/logic/date-scalars';
import {translate} from "@jsverse/transloco";
import {marker} from "@nyffels/transloco-keys-manager/marker";
import {Invoice} from "../document/invoice";
import {Creditnote} from "../document/creditnote";


export enum PeppolStatusEnum {
  CREATED = 1,
  SENT = 2,
  FAILED = 3,
}

export enum PeppolObjectEnum {
  INVOICE = 1,
  CREDITNOTE = 2,
}

export class Peppol implements IPeppol {
  id: number = 0;
  subscriberId: number = 0;
  userId: number = 0;
  user: User;
  object: PeppolObjectEnum = PeppolObjectEnum.INVOICE;
  objectId: number = 0;
  created: string = "";

  invoice: Invoice;
  creditnote: Creditnote;

  private _created: Date;

  get Created() {return this._created;}

  set Created(date: Date) {
    this._created = date;
    this.created = SetDateTimeScalar(date);
  }

  error: string = '';

  get ServerError() {
    try {
      const obj = JSON.parse(this.error);
      return obj;
    } catch {
      return this.error;
    }
  }

  status: PeppolStatusEnum = PeppolStatusEnum.CREATED;
  externalId: string = '';

  get Error() {
    try {
      const obj = JSON.parse(this.error);
      return obj;
    } catch {
      return this.error;
    }
  }

  constructor(peppol: IPeppol) {
    if (peppol === null) {
      return;
    }

    constructFromInterface(this, peppol);

    if (peppol.created) {this._created = GetDateTimeScalar(peppol.created);}
    if (peppol.user) {
      this.user = new User(peppol.user);
    }
    if (peppol.invoice) {
      this.invoice = new Invoice(peppol.invoice);
    }

    if (peppol.creditnote) {
      this.creditnote = new Creditnote(peppol.creditnote);
    }
  }

  get Status() {
    switch (this.status) {
      case PeppolStatusEnum.CREATED:
        return translate(marker('label.peppol.status.created', 'Verzonden'));
      case PeppolStatusEnum.FAILED:
        return translate(marker('label.peppol.status.failed', 'Mislukt'));
      case PeppolStatusEnum.SENT:
        return translate(marker('label.peppol.status.sent', 'Ontvangen'));
    }
  }
}
