import {Address} from 'app/shared/models/relation/address';
import {Company} from 'app/shared/models/entity/company';
import {WorkPerformance} from 'app/shared/models/document/workperformance';
import moment from 'moment';
import {Invoice} from '../document/invoice';
import {Relation} from '../relation/relation.class';
import {constructFromInterface} from 'app/core/logic/map.logic';
import {ITranslocation, ITranslocationExpense, ITranslocationExpenseInput, ITranslocationInput} from "../../../core/graphql/generated/types";
import {GetDateScalar, GetDateTimeScalar, SetDateScalar, SetDateTimeScalar} from "../../../core/logic/date-scalars";
import {Country} from "./country";
import {countries} from "../../data/country";

export class Translocation implements ITranslocation {
  id: number = 0;
  subscriber_id: number = 0;
  date: string = "";
  private _date: Date;

  get Date() {return this._date;}

  set Date(date: Date) {
    this._date = date;
    this.date = SetDateScalar(date);
  }

  company_id: number = 0;
  company_name: string = '';
  company: Company;
  customer_id: number = 0;
  customer_name: string = '';
  customer: Relation;
  from_adr_street: string = '';
  from_adr_number: string = '';
  from_adr_city: string = '';
  from_adr_zip: string = '';
  from_adr_county: string = '';
  from_adr_country_id: number = 0;
  from_adr_country: Country;

  get From_adr(): Address {
    return new Address({
      id: null,
      street: this.from_adr_street,
      number: this.from_adr_number,
      city: this.from_adr_city,
      zip: this.from_adr_zip,
      county: this.from_adr_county,
      country_id: this.from_adr_country_id,
      country: this.from_adr_country,
      default: null,
      subscriber_id: this.subscriber_id,
    });
  }

  end_adr_street: string = '';
  end_adr_number: string = '';
  end_adr_city: string = '';
  end_adr_zip: string = '';
  end_adr_county: string = '';
  end_adr_country_id: number = 0;
  end_adr_country: Country;

  get End_adr(): Address {
    return new Address({
      id: null,
      street: this.end_adr_street,
      number: this.end_adr_number,
      city: this.end_adr_city,
      zip: this.end_adr_zip,
      county: this.end_adr_county,
      country_id: this.end_adr_country_id,
      country: this.end_adr_country,
      default: null,
      subscriber_id: this.subscriber_id,
    });
  }

  distance: number = 0;
  invoice_id: number = 0;
  invoice: Invoice;
  workPerformance_id: number = 0;
  workPerformance: WorkPerformance;
  created: string = "";

  private _created: Date;

  get Created() {return this._created;}

  set Created(date: Date) {
    this._created = date;
    this.created = SetDateTimeScalar(date);
  }

  updated: string = "";
  private _updated: Date;

  get Updated() {return this._updated;}

  set Updated(date: Date) {
    this._updated = date;
    this.updated = SetDateTimeScalar(date);
  }

  expense: TranslocationExpense;

  get Valid(): boolean {
    return (
      this.date != null &&
      this.company_name?.trim().length > 0 &&
      this.customer_name?.trim().length > 0 &&
      this.from_adr_street?.trim().length > 0 &&
      this.from_adr_number?.trim().length > 0 &&
      this.from_adr_city?.trim().length > 0 &&
      this.from_adr_zip?.trim().length > 0 &&
      this.from_adr_country_id > 0 &&
      this.end_adr_street?.trim().length > 0 &&
      this.end_adr_number?.trim().length > 0 &&
      this.end_adr_city?.trim().length > 0 &&
      this.end_adr_zip?.trim().length > 0 &&
      this.end_adr_country_id > 0 &&
      this.distance !== null
    );
  }

  constructor(translocation: ITranslocation = null) {
    if (translocation === null) {
      return;
    }

    constructFromInterface(this, translocation);

    if (translocation.date) {this._date = GetDateScalar(translocation.date);}
    if (translocation.created) {this._created = GetDateTimeScalar(translocation.created);}
    if (translocation.updated) {this._updated = GetDateTimeScalar(translocation.updated);}
    if (translocation.company) {
      this.company = new Company(translocation.company);
    }
    if (translocation.customer) {
      this.customer = new Relation(translocation.customer);
    }
    if (translocation.from_adr_country_id) {
      this.from_adr_country = new Country(countries.find(c => c.id == translocation.from_adr_country_id));
    }
    if (translocation.end_adr_country_id) {
      this.end_adr_country = new Country(countries.find(c => c.id == translocation.end_adr_country_id));
    }
    if (translocation.invoice) {
      this.invoice = new Invoice(translocation.invoice);
    }
    if (translocation.workPerformance) {
      this.workPerformance = new WorkPerformance(translocation.workPerformance);
    }
    if (translocation.expense) {
      this.expense = new TranslocationExpense(translocation.expense);
    }
  }

  public convertToInput(): ITranslocationInput {
    return {
      id: this.id ? this.id : null,
      date: SetDateScalar(this.date ? moment(this.date)
        .toDate() : null),
      company_id: this.company_id ? +this.company_id : null,
      company_name: this.company_name,
      customer_id: this.customer_id ? +this.customer_id : null,
      customer_name: this.customer_name,
      from_adr_street: this.from_adr_street,
      from_adr_number: this.from_adr_number,
      from_adr_city: this.from_adr_city,
      from_adr_zip: this.from_adr_zip,
      from_adr_county: this.from_adr_county,
      from_adr_country_id: this.from_adr_country_id ? +this.from_adr_country_id : null,
      end_adr_street: this.end_adr_street,
      end_adr_number: this.end_adr_number,
      end_adr_city: this.end_adr_city,
      end_adr_zip: this.end_adr_zip,
      end_adr_county: this.end_adr_county,
      end_adr_country_id: this.end_adr_country_id ? +this.end_adr_country_id : null,
      distance: +this.distance,
      invoice_id: +this.invoice_id,
      workPerformance_id: +this.workPerformance_id,
    };
  }

  public static new(): Translocation {
    return new Translocation({
      id: null,
      subscriber_id: null,
      date: SetDateScalar(new Date()),
      company_id: null,
      company_name: null,
      company: null,
      customer_id: null,
      customer_name: null,
      customer: null,
      from_adr_street: null,
      from_adr_number: null,
      from_adr_city: null,
      from_adr_zip: null,
      from_adr_county: null,
      from_adr_country_id: null,
      from_adr_country: null,
      end_adr_street: null,
      end_adr_number: null,
      end_adr_city: null,
      end_adr_zip: null,
      end_adr_county: null,
      end_adr_country_id: null,
      end_adr_country: null,
      distance: null,
      invoice_id: null,
      invoice: null,
      workPerformance_id: null,
      workPerformance: null,
      created: null,
      updated: null,
      expense: null,
    });
  }
}

export class TranslocationExpense implements ITranslocationExpense {
  id: number = 0;
  subscriberId: number = 0;
  start: string = "";
  private _start: Date;

  get Start() {return this._start;}

  set Start(date: Date) {
    this._start = date;
    this.start = SetDateScalar(date);
  }

  end: string = "";
  private _end: Date;

  get End() {return this._end;}

  set End(date: Date) {
    this._end = date;
    this.end = SetDateScalar(date);
  }

  expense: number = 0;
  created: string = "";

  private _created: Date;

  get Created() {return this._created;}

  set Created(date: Date) {
    this._created = date;
    this.created = SetDateTimeScalar(date);
  }

  updated: string = "";
  private _updated: Date;

  get Updated() {return this._updated;}

  set Updated(date: Date) {
    this._updated = date;
    this.updated = SetDateTimeScalar(date);
  }

  constructor(expense: ITranslocationExpense = null) {
    if (expense === null) {
      return;
    }

    if (expense.start) {this._start = GetDateScalar(expense.start);}
    if (expense.end) {this._end = GetDateScalar(expense.end);}
    if (expense.created) {this._created = GetDateTimeScalar(expense.created);}
    if (expense.updated) {this._updated = GetDateTimeScalar(expense.updated);}
    constructFromInterface(this, expense);
  }

  public static new(): TranslocationExpense {
    return new TranslocationExpense({
      id: null,
      subscriberId: null,
      start: null,
      end: null,
      expense: 0,
      created: null,
      updated: null,
    });
  }

  public get Valid(): boolean {
    return this.expense !== undefined && this.expense !== null && !!this.start && !!this.end;
  }

  convertToInput(): ITranslocationExpenseInput {
    return {
      id: this.id ? +this.id : null,
      end: SetDateScalar(this.end ? moment(this.end)
        .toDate() : null),
      expense: +this.expense,
      start: SetDateScalar(this.start ? moment(this.start)
        .toDate() : null),
    };
  }
}
