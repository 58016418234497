import {constructFromInterface} from 'app/core/logic/map.logic';
import _ from 'lodash';
import {ITransactionGateway, ITransactionGatewayInput, ITransactionGatewayMutationInput} from "../../../core/graphql/generated/types";
import {GetDateTimeScalar, SetDateTimeScalar} from 'app/core/logic/date-scalars';

export class TransactionGateway implements ITransactionGateway {
  id: number = 0;
  subscriber_id: number = 0;
  name: string = '';
  description: string = '';
  created: string = "";
  registerInCashbook: boolean = false;

  private _created: Date;

  get Created() {return this._created;}

  set Created(date: Date) {
    this._created = date;
    this.created = SetDateTimeScalar(date);
  }

  updated: string = "";
  private _updated: Date;

  get Updated() {return this._updated;}

  set Updated(date: Date) {
    this._updated = date;
    this.updated = SetDateTimeScalar(date);
  }

  get Validation(): boolean {
    return this.name != null && this.name.trim().length > 0;
  }

  constructor(transactionGateway: ITransactionGateway = null) {
    if (transactionGateway === null) {
      return;
    }

    constructFromInterface(this, transactionGateway);

    if (transactionGateway.created) {this._created = GetDateTimeScalar(transactionGateway.created);}
    if (transactionGateway.updated) {this._updated = GetDateTimeScalar(transactionGateway.updated);}
  }

  public static new(): TransactionGateway {
    return new TransactionGateway({
      id: null,
      subscriber_id: null,
      name: null,
      description: null,
      created: null,
      updated: null,
      registerInCashbook: false
    });
  }

  public convertToInput(): ITransactionGatewayInput {
    return {
      id: this.id,
      name: this.name,
      description: this.description,
      registerInCashbook: this.registerInCashbook
    };
  }

  public createMutationInput(originalTransactionGateway: TransactionGateway = null): ITransactionGatewayMutationInput {
    let mutationInput: ITransactionGatewayMutationInput = {id: this.id, transactionGateway: null};
    mutationInput.transactionGateway = _.isEqual(this.convertToInput(), originalTransactionGateway?.convertToInput()) ? null : this.convertToInput(); /* Check if transactiongateway is updated */

    return mutationInput;
  }
}
