import {Service} from './service';
import {Relation} from '../relation/relation.class';
import {constructFromInterface} from 'app/core/logic/map.logic';
import {IPrePaidService, IPrePaidServiceTransaction, IPrePaidServiceTransactionInput} from "../../../core/graphql/generated/types";
import {GetDateTimeScalar, SetDateTimeScalar} from "../../../core/logic/date-scalars";
import {translate} from "@jsverse/transloco";
import {marker} from "@nyffels/transloco-keys-manager/marker";

export class PrePaidService implements IPrePaidService {
  id: number = 0;
  subscriberId: number = 0;
  serviceId: number = 0;
  service: Service;
  relationId: number = 0;
  relation: Relation;
  status: PrePaidServiceStatusEnum;
  amount: number = 0;
  created: string = "";

  private _created: Date;

  get Created() {return this._created;}

  set Created(date: Date) {
    this._created = date;
    this.created = SetDateTimeScalar(date);
  }

  updated: string = "";
  private _updated: Date;

  get Updated() {return this._updated;}

  set Updated(date: Date) {
    this._updated = date;
    this.updated = SetDateTimeScalar(date);
  }

  transactions: PrePaidServiceTransaction[] = [];

  get Status(): string {
    switch (this.status) {
      case PrePaidServiceStatusEnum.SOLDOUT:
        return translate(marker('label.prepaidService.status.soldout', 'Uitverkocht'));
      case PrePaidServiceStatusEnum.TOINVOICE:
        return translate(marker('label.prepaidService.status.toInvoice', 'Te factureren'));
      case PrePaidServiceStatusEnum.ACTIVE:
        return translate(marker('label.prepaidService.status.active', 'Actief'));
    }
  }

  constructor(prePaidService: IPrePaidService) {
    constructFromInterface(this, prePaidService);

    if (prePaidService.created) {this._created = GetDateTimeScalar(prePaidService.created);}
    if (prePaidService.updated) {this._updated = GetDateTimeScalar(prePaidService.updated);}
    if (prePaidService.service) {
      this.service = new Service(prePaidService.service);
    }
    if (prePaidService.relation) {
      this.relation = new Relation(prePaidService.relation);
    }
    if (prePaidService.transactions) {
      this.transactions = prePaidService.transactions.map(transaction => new PrePaidServiceTransaction(transaction));
    }
  }
}

export class PrePaidServiceTransaction implements IPrePaidServiceTransaction {
  id: number = 0;
  subscriberId: number = 0;
  prePaidServiceId: number = 0;
  relationId: number = 0;
  relation: Relation;
  serviceId: number = 0;
  service: Service;
  amount: number = 0;
  internalNote: string = '';
  note: string = '';
  documentTypeId: PrePaidTransactionDocumentTypeEnum;
  documentId: number = 0;
  created: string = "";

  private _created: Date;

  get Created() {return this._created;}

  set Created(date: Date) {
    this._created = date;
    this.created = SetDateTimeScalar(date);
  }

  updated: string = "";
  private _updated: Date;

  get Updated() {return this._updated;}

  set Updated(date: Date) {
    this._updated = date;
    this.updated = SetDateTimeScalar(date);
  }

  get Validation() {
    return true; // TODO
  }

  constructor(transaction: IPrePaidServiceTransaction = null) {
    if (transaction === null) {
      return;
    }

    constructFromInterface(this, transaction);

    if (transaction.relation) {
      this.relation = new Relation(transaction.relation);
    }
    if (transaction.service) {
      this.service = new Service(transaction.service);
    }
  }

  public static new() {
    return new PrePaidServiceTransaction({
      id: null,
      subscriberId: null,
      prePaidServiceId: null,
      relationId: null,
      relation: null,
      serviceId: null,
      service: null,
      amount: null,
      internalNote: null,
      note: null,
      documentTypeId: null,
      documentId: null,
      created: null,
      updated: null,
    });
  }

  toInput(): IPrePaidServiceTransactionInput {
    return {
      id: this.id ? +this.id : null,
      prePaidServiceId: this.prePaidServiceId ? this.prePaidServiceId : null,
      relationId: this.relationId ? +this.relationId : null,
      serviceId: this.serviceId ? +this.serviceId : null,
      amount: this.amount ? +this.amount : null,
      internalNote: this.internalNote,
      note: this.note,
      documentTypeId: this.documentTypeId ? +this.documentTypeId : null,
      documentId: this.documentId ? +this.documentId : null,
    };
  }
}

export enum PrePaidServiceStatusEnum {
  ACTIVE = 1,
  TOINVOICE = 2,
  SOLDOUT = 3,
}

export enum PrePaidTransactionDocumentTypeEnum {
  SALESINVOICE = 1,
  WORKPERFORMANCE = 2,
}
