import {User} from 'app/shared/models/entity/user';
import {Document} from '../general/document';
import {constructFromInterface} from 'app/core/logic/map.logic';
import {IEmail, IEmailAttachment, IEmailAttachmentInput, IEmailInput, IEmailReceiver, IEmailReceiverInput} from "../../../core/graphql/generated/types";
import {GetDateTimeScalar, SetDateTimeScalar} from "../../../core/logic/date-scalars";
import {translate} from "@jsverse/transloco";
import {marker} from "@nyffels/transloco-keys-manager/marker";


export class Email implements IEmail {
  id: number = 0;
  subscriber_id: number = 0;
  user_id: number = 0;
  user: User;
  subject: string = '';
  body: string = '';
  termsAndConditions: number = 0;

  status: EmailStatusEnum = EmailStatusEnum.CONCEPT;

  get Status(): string {
    return Email.GetStatus(this.status)
  }

  static GetStatus(status: EmailStatusEnum) {
    switch (status) {
      case EmailStatusEnum.CONCEPT:
        return translate(marker('status.email.concept', 'Concept'));
      case EmailStatusEnum.FAILED:
        return translate(marker('status.email.failed', 'Mislukt'));
      case EmailStatusEnum.PROCESSING:
        return translate(marker('status.email.processing', 'Verwerken'));
      case EmailStatusEnum.QUEUE:
        return translate(marker('status.email.queue', 'In wacht'));
      case EmailStatusEnum.SEND:
        return translate(marker('status.email.send', 'Verzonden'));
    }
  }

  priority: EmailPriorityEnum;

  get Priority(): string {
    switch (this.priority) {
      case EmailPriorityEnum.LOW:
        return translate(marker('priority.email.low', 'Laag'));
      case EmailPriorityEnum.NORMAL:
        return translate(marker('priority.email.normal', 'Normaal'));
      case EmailPriorityEnum.HIGH:
        return translate(marker('priority.email.High', 'Hoog'));
    }
  }

  send: string = '';
  private _send: Date;

  get Send() {return this._send;}

  set Send(date: Date) {
    this._send = date;
    this.send = SetDateTimeScalar(date);
  }

  result: string = '';

  get Result() {
    try {
      const obj = JSON.parse(this.result);
      return obj;
    } catch {
      return this.result;
    }
  }

  receivers: EmailReceiver[] = [];

  get Receivers(): string {
    return this.receivers
      .filter((x) => x.type == EmailReceiverTypeEnum.TO)
      .map((x) => x.email)
      .join(', ');
  }

  get CC(): string {
    return this.receivers
      .filter((x) => x.type == EmailReceiverTypeEnum.CC)
      .map((x) => x.email)
      .join(', ');
  }

  get BCC(): string {
    return this.receivers
      .filter((x) => x.type == EmailReceiverTypeEnum.BCC)
      .map((x) => x.email)
      .join(', ');
  }

  attachments: EmailAttachment[] = [];

  created: string = "";

  private _created: Date;

  get Created() {return this._created;}

  set Created(date: Date) {
    this._created = date;
    this.created = SetDateTimeScalar(date);
  }

  updated: string = "";
  private _updated: Date;

  get Updated() {return this._updated;}

  set Updated(date: Date) {
    this._updated = date;
    this.updated = SetDateTimeScalar(date);
  }

  firstRead: string = "";
  private _firstRead: Date;

  get FirstRead() {return this._firstRead;}

  set FirstRead(date: Date) {
    this._firstRead = date;
    this.firstRead = SetDateTimeScalar(date);
  }

  lastRead: string = "";
  private _lastRead: Date;

  get LastRead() {return this._lastRead;}

  set LastRead(date: Date) {
    this._lastRead = date;
    this.lastRead = SetDateTimeScalar(date);
  }

  readCount: number = 0;

  constructor(email: IEmail = null) {
    if (email === null) {
      return;
    }

    constructFromInterface(this, email);

    if (email.send) {this._send = GetDateTimeScalar(email.send);}
    if (email.created) {this._created = GetDateTimeScalar(email.created);}
    if (email.updated) {this._updated = GetDateTimeScalar(email.updated);}
    if (email.firstRead) {this._firstRead = GetDateTimeScalar(email.firstRead);}
    if (email.lastRead) {this._lastRead = GetDateTimeScalar(email.lastRead);}
    if (email.user) {
      this.user = new User(email.user);
    }

    if (email.receivers) {
      this.receivers = email.receivers.map((x) => new EmailReceiver(x));
    }
    if (email.attachments) {
      this.attachments = email.attachments.map((x) => new EmailAttachment(x));
    }
  }

  public static new(): Email {
    return new Email({
      id: null,
      subscriber_id: null,
      user_id: null,
      user: null,
      subject: null,
      body: null,
      status: null,
      priority: EmailPriorityEnum.NORMAL,
      send: null,
      result: null,
      receivers: [],
      attachments: [],
      created: null,
      updated: null,
      firstRead: null,
      lastRead: null,
      readCount: null,
      termsAndConditions: null,
    });
  }

  public convertToInput(): IEmailInput {
    return {
      id: +this.id,
      subject: this.subject,
      body: this.body,
      priority: +this.priority,
      termsAndConditions: +this.termsAndConditions,
    };
  }
}

export class EmailAttachment implements IEmailAttachment {
  id: number = 0;
  subscriber_id: number = 0;
  mail_id: number = 0;
  sysdoc_id: number = 0;
  sysdoc: Document;

  constructor(attachment: IEmailAttachment = null) {
    if (attachment === null) {
      return;
    }

    constructFromInterface(this, attachment);

    if (attachment.sysdoc) {
      this.sysdoc = new Document(attachment.sysdoc);
    }
  }

  public static new(): EmailAttachment {
    return new EmailAttachment({
      id: null,
      subscriber_id: null,
      mail_id: null,
      sysdoc_id: null,
      sysdoc: null,
    });
  }

  public convertToInput(): IEmailAttachmentInput {
    return {
      id: this.id,
      sysdoc_id: this.sysdoc_id,
    };
  }
}

export class EmailReceiver implements IEmailReceiver {
  id: number = 0;
  subscriber_id: number = 0;
  mail_id: number = 0;
  email: string = '';
  type: EmailReceiverTypeEnum = EmailReceiverTypeEnum.TO;

  get Type(): string {
    switch (this.type) {
      case EmailReceiverTypeEnum.TO:
        return translate(marker('type.emailreceiver.to', 'Aan'));
      case EmailReceiverTypeEnum.CC:
        return translate(marker('type.emailreceiver.cc', 'CC'));
      case EmailReceiverTypeEnum.BCC:
        return translate(marker('type.emailreceiver.bcc', 'BCC'));
    }
  }

  constructor(receiver: IEmailReceiver = null) {
    if (receiver === null) {
      return;
    }

    constructFromInterface(this, receiver);
  }

  public static new(): EmailReceiver {
    return new EmailReceiver({
      id: null,
      subscriber_id: null,
      mail_id: null,
      email: null,
      type: EmailReceiverTypeEnum.TO,
    });
  }

  public convertToInput(): IEmailReceiverInput {
    return {
      id: this.id,
      email: this.email,
      type: this.type,
    };
  }
}

export enum EmailReceiverTypeEnum {
  TO = 1,
  CC = 2,
  BCC = 3,
}

export enum EmailStatusEnum {
  CONCEPT = 1,
  QUEUE = 2,
  PROCESSING = 3,
  SEND = 4,
  FAILED = 5,
}

export enum EmailPriorityEnum {
  LOW = 1,
  NORMAL = 2,
  HIGH = 3,
}
