import _ from 'lodash';
import {User} from '../entity/user';
import {MutationInputObject} from 'app/shared/models/system/mutation-object';
import {Report} from './report';
import {constructFromInterface} from 'app/core/logic/map.logic';
import {IReportRequest, IReportRequestInput, IReportRequestMutationInput, IReportRequestValue, IReportRequestValueInput, IReportRequestValueMutationInput} from "../../../core/graphql/generated/types";
import {GetDateTimeScalar, SetDateTimeScalar} from "../../../core/logic/date-scalars";


export class ReportRequest implements IReportRequest {
  id: number = 0;
  reportId: number = 0;
  report: Report;
  subscriberId: number = 0;
  userId: number = 0;
  user: User;
  name: string = '';
  status: ReportRequestStatus = ReportRequestStatus.REQUESTED;
  requested: string = "";
  private _requested: Date;

  get Requested() {return this._requested;}

  set Requested(date: Date) {
    this._requested = date;
    this.requested = SetDateTimeScalar(date);
  }

  completed: string = "";
  private _completed: Date;

  get Completed() {return this._completed;}

  set Completed(date: Date) {
    this._completed = date;
    this.completed = SetDateTimeScalar(date);
  }

  raw: string = '';
  Raw: any;
  values: ReportRequestValue[] = [];

  constructor(request: IReportRequest) {
    constructFromInterface(this, request);

    if (request.completed) {this._completed = GetDateTimeScalar(request.completed);}
    if (request.requested) {this._requested = GetDateTimeScalar(request.requested);}
    if (request.report) {
      this.report = new Report(request.report);
    }
    if (request.user) {
      this.user = new User(request.user);
    }
    if (request.raw) {
      this.Raw = JSON.parse(request.raw);
    }

    if (request.values) {
      this.values = request.values.map((value) => new ReportRequestValue(value));
    }
  }

  public static new(): ReportRequest {
    return new ReportRequest({
      id: null,
      reportId: null,
      report: null,
      subscriberId: null,
      userId: null,
      user: null,
      name: null,
      status: null,
      requested: null,
      completed: null,
      raw: null,
      values: [],
    });
  }

  public convertToInput(): IReportRequestInput {
    return {
      id: this.id ? +this.id : null,
      name: this.name,
      reportId: this.reportId ? +this.reportId : null,
    };
  }

  public createMutationInput(values: MutationInputObject<ReportRequestValue>, originalRequest: ReportRequest): IReportRequestMutationInput {
    let mutationInput: IReportRequestMutationInput = {id: this.id, request: null, values: null};
    mutationInput.request = _.isEqual(this.convertToInput(), originalRequest?.convertToInput()) ? null : this.convertToInput();

    /* Set values */
    mutationInput.values = {
      deletes: values.deletes.map((x) => +x),
      updates: values.updates.map((x) => {
        return {
          id: x.id,
          value: x.convertToInput(),
        } as IReportRequestValueMutationInput;
      }),
    };

    return mutationInput;
  }
}

export class ReportRequestValue implements IReportRequestValue {
  id: number = 0;
  requestId: number = 0;
  subscriberId: number = 0;
  name: string = '';
  value: string = '';

  constructor(value: IReportRequestValue) {
    constructFromInterface(this, value);
  }

  public convertToInput(): IReportRequestValueInput {
    return {
      id: this.id ? +this.id : null,
      name: this.name,
      requestId: this.requestId ? +this.requestId : null,
      value: this.value ? this.value.toString() : null,
    };
  }
}

export enum ReportRequestStatus {
  REQUESTED = 1,
  GENERATING = 2,
  COMPLETED = 3,
}
