import {constructFromInterface} from 'app/core/logic/map.logic';
import moment from 'moment';
import {translate} from "@jsverse/transloco";
import {marker} from "@nyffels/transloco-keys-manager/marker";
import {IReport, IReportValue} from "../../../core/graphql/generated/types";


export class Report implements IReport {
  id: number = 0;
  name: string = '';
  description: string = '';
  values: ReportValue[] = [];

  get Name() {
    return translate(marker(this.name));
  }

  get Description() {
    return translate(marker(this.description));
  }

  constructor(report: IReport = null) {
    if (report === null) {
      return;
    }

    constructFromInterface(this, report);

    if ((report.values ?? []).length > 0) {
      this.values = report.values.map((value) => new ReportValue(value));
    }
  }
}

export class ReportValue implements IReportValue {
  id: string = '';
  name: string = '';
  type: string = '';
  default: string = '';
  optional: boolean = false;

  constructor(value: IReportValue) {
    if (value === null) {
      return;
    }

    constructFromInterface(this, value);
  }

  get Default() {
    switch (this.type) {
      case 'date':
        return moment(this.default)
          .isValid() ? moment(this.default)
          .toDate() : null;
      case 'boolean':
        return this.default == true.toString() ? true : false;
      default:
        return null;
    }
  }
}
