import {Company} from 'app/shared/models/entity/company';
import _ from 'lodash';
import {Document} from 'app/shared/models/general/document';
import {Transaction} from 'app/shared/models/general/transaction';
import {Country} from 'app/shared/models/general/country';
import {Address} from 'app/shared/models/relation/address';
import {TranslationService} from 'app/core/services/translation.service';
import moment from 'moment';
import {Product} from 'app/shared/models/article/product';
import {ArticleTypeEnum} from 'app/shared/models/article/articletype.enum';
import {Relation} from '../relation/relation.class';
import {AccountNumber} from '../ledger/account-number';
import {LedgerExportTypeEnum} from '../ledger/ledger-export';
import {constructFromInterface} from 'app/core/logic/map.logic';
import {Journal} from '../ledger/journal';
import {translate} from '@jsverse/transloco';
import {marker} from '@nyffels/transloco-keys-manager/marker';
import {VatService} from "../../../core/services/vat.service";
import {MutationInputObject} from "../system/mutation-object";
import {IDocumentMutationInput, IPurchaseInvoice, IPurchaseInvoiceInput, IPurchaseInvoiceMutationInput, IPurchaseInvoiceSubline, IPurchaseInvoiceSublineInput, IPurchaseInvoiceSublineMutationInput, ITransactionMutationInput} from "../../../core/graphql/generated/types";
import {GetDateScalar, GetDateTimeScalar, SetDateScalar, SetDateTimeScalar} from "../../../core/logic/date-scalars";
import {countries} from "../../data/country";

export class PurchaseInvoice implements IPurchaseInvoice {
  id: number = 0;
  subscriberId: number = 0;
  number: string = '';

  companyId: number = 0;
  companyName: string = '';
  companyVatNumber: string = '';
  companyAddressStreet: string = '';
  companyAddressNumber: string = '';
  companyAddressZip: string = '';
  companyAddressCity: string = '';
  companyAddressCounty: string = '';
  companyAddressCountryId: number = 0;
  companyAddressCountry: Country;

  get company_address(): Address {
    return new Address({
      id: null,
      street: this.companyAddressStreet,
      number: this.companyAddressNumber,
      city: this.companyAddressCity,
      zip: this.companyAddressZip,
      county: this.companyAddressCounty,
      country_id: this.companyAddressCountryId,
      country: this.companyAddressCountry,
      default: null,
      subscriber_id: this.subscriberId,
    });
  }

  company: Company;

  relationId: number = 0;
  relationName: string = '';
  relationVatNumber: string = '';
  relationAddressStreet: string = '';
  relationAddressNumber: string = '';
  relationAddressZip: string = '';
  relationAddressCity: string = '';
  relationAddressCounty: string = '';
  relationAddressCountryId: number = 0;
  relationAddressCountry: Country;

  get supplier_address(): Address {
    return new Address({
      id: null,
      street: this.relationAddressStreet,
      number: this.relationAddressNumber,
      city: this.relationAddressCity,
      zip: this.relationAddressZip,
      county: this.relationAddressCounty,
      country_id: this.relationAddressCountryId,
      country: this.relationAddressCountry,
      default: null,
      subscriber_id: this.subscriberId,
    });
  }

  relation: Relation;

  value: number = 0;
  vatValue: number = 0;
  valueIncl: number = 0;
  intnote: string = '';
  note: string = '';
  date: string = "";
  private _date: Date;

  get Date() {return this._date;}

  set Date(date: Date) {
    this._date = date;
    this.date = SetDateScalar(date);
  }

  enddate: string = "";
  private _enddate: Date;

  get Enddate() {return this._enddate;}

  set Enddate(date: Date) {
    this._enddate = date;
    this.enddate = SetDateScalar(date);
  }

  status: PurchaseInvoiceStatusEnum = PurchaseInvoiceStatusEnum.CONCEPT;

  get Status(): string {
    switch (this.status) {
      case PurchaseInvoiceStatusEnum.CONCEPT:
        return translate(marker('label.purchaseInvoice.status.concept', 'Concept'));
      case PurchaseInvoiceStatusEnum.UNPAID:
        return translate(marker('label.purchaseInvoice.status.unpaid', 'Onbetaald'));
      case PurchaseInvoiceStatusEnum.PARTIALLYPAID:
        return translate(marker('label.purchaseInvoice.status.partiallyPaid', 'Gedeeltelijk betaald\n'));
      case PurchaseInvoiceStatusEnum.PAID:
        return translate(marker('label.purchaseInvoice.status.paid', 'Betaald'));
    }
  }

  exported: string = "";
  private _exported: Date;

  get Exported() {return this._exported;}

  set Exported(date: Date) {
    this._exported = date;
    this.exported = SetDateTimeScalar(date);
  }

  exportedType: LedgerExportTypeEnum = LedgerExportTypeEnum.PDF;

  get ExportedType(): string {
    switch (this.exportedType) {
      case LedgerExportTypeEnum.PDF:
        return translate(marker('label.ledgerExportType.email', 'E-mail'));
      case LedgerExportTypeEnum.OCTOPUS:
        return translate(marker('label.ledgerExportType.octopus', 'Octopus'));
      case LedgerExportTypeEnum.ADMISOL:
        return translate(marker('label.ledgerExportType.admisol', 'Admisol'));
      case LedgerExportTypeEnum.YUKI:
        return translate(marker('label.ledgerExportType.yuki', 'Yuki'));
      case LedgerExportTypeEnum.MANUAL:
        return translate(marker('label.ledgerExportType.manual', 'Manueel gemarkeerd als geëxporteerd'));
      case LedgerExportTypeEnum.EXACT:
        return translate(marker('label.ledgerExportType.exactApi', 'Exact'));
    }
  }

  paymentReference: string = '';
  created: string = "";
  private _created: Date;

  get Created() {return this._created;}

  set Created(date: Date) {
    this._created = date;
    this.created = SetDateTimeScalar(date);
  }

  updated: string = "";
  private _updated: Date;

  get Updated() {return this._updated;}

  set Updated(date: Date) {
    this._updated = date;
    this.updated = SetDateTimeScalar(date);
  }

  journalId: number = 0;
  journal: Journal;
  paymentBic: string = '';
  paymentIban: string = '';
  vatCode: string = "";
  accountNumberId: number = 0;
  accountNumber: AccountNumber;

  sublines: PurchaseInvoiceSubline[] = [];
  documents: Document[] = [];
  transactions: Transaction[] = [];

  get CanConfirm(): boolean {
    return (
      this.number != null &&
      this.number.trim().length > 0 &&
      this.date &&
      moment(this.date)
        .isValid() &&
      this.companyName != null &&
      this.companyName.trim() != '' &&
      this.companyVatNumber != null &&
      this.companyVatNumber.trim() != '' &&
      this.relationId != null &&
      this.relationId > 0 &&
      this.relationName != null &&
      this.relationName.trim() != '' &&
      this.value !== null &&
      this.sublines.length > 0 &&
      this.status == PurchaseInvoiceStatusEnum.CONCEPT &&
      this.valueIncl !== null &&
      this.vatValue !== null
    );
  }

  constructor(invoice: IPurchaseInvoice = null) {
    if (invoice === null) {
      return;
    }

    constructFromInterface(this, invoice);

    if (invoice.date) {this._date = GetDateScalar(invoice.date)}
    if (invoice.enddate) {this._enddate = GetDateScalar(invoice.enddate)}
    if (invoice.exported) {this._exported = GetDateTimeScalar(invoice.exported)}
    if (invoice.created) {this._created = GetDateTimeScalar(invoice.created)}
    if (invoice.updated) {this._updated = GetDateTimeScalar(invoice.updated)}
    if (invoice.companyAddressCountryId) {
      this.companyAddressCountry = new Country(countries.find(x => x.id == invoice.companyAddressCountryId));
    }
    if (invoice.company) {
      this.company = new Company(invoice.company);
    }
    if (invoice.relationAddressCountryId) {
      this.relationAddressCountry = new Country(countries.find(x => x.id == invoice.relationAddressCountryId));
    }
    if (invoice.relation) {
      this.relation = new Relation(invoice.relation);
    }
    if (invoice.accountNumber) {
      this.accountNumber = new AccountNumber(invoice.accountNumber);
    }
    if (invoice.journal) {
      this.journal = new Journal(invoice.journal);
    }
    if (invoice.sublines) {
      this.sublines = invoice.sublines.map((x) => new PurchaseInvoiceSubline(x));
    }
    if (invoice.documents) {
      this.documents = invoice.documents.map((x) => new Document(x));
    }
    if (invoice.transactions) {
      this.transactions = invoice.transactions.map((x) => new Transaction(x));
    }
  }

  public calculateValue(): void {
    this.value = _.sumBy(this.sublines, (x) => +x.price);
    this.valueIncl = _.sumBy(this.sublines, (x) => +x.priceIncl);
    this.vatValue = _.sumBy(this.sublines, (x) => +x.vatprice);
  }

  public static new(): PurchaseInvoice {
    return new PurchaseInvoice({
      id: null,
      subscriberId: null,
      number: null,
      companyId: null,
      companyName: null,
      companyVatNumber: null,
      companyAddressStreet: null,
      companyAddressNumber: null,
      companyAddressZip: null,
      companyAddressCity: null,
      companyAddressCounty: null,
      companyAddressCountryId: null,
      companyAddressCountry: null,
      company: null,
      relationId: null,
      relationName: null,
      relationVatNumber: null,
      relationAddressStreet: null,
      relationAddressNumber: null,
      relationAddressZip: null,
      relationAddressCity: null,
      relationAddressCounty: null,
      relationAddressCountryId: null,
      relationAddressCountry: null,
      relation: null,
      value: 0,
      vatValue: 0,
      valueIncl: 0,
      intnote: null,
      note: null,
      date: null,
      enddate: null,
      status: PurchaseInvoiceStatusEnum.CONCEPT,
      paymentReference: null,
      created: null,
      updated: null,
      journalId: null,
      journal: null,
      sublines: [],
      documents: [],
      transactions: [],
      exported: null,
      exportedType: null,
      paymentBic: null,
      paymentIban: null,
      vatCode: null,
      accountNumberId: null,
      accountNumber: null,
    });
  }

  public convertToInput(): IPurchaseInvoiceInput {
    return {
      id: this.id !== null && this.id !== undefined ? +this.id : null,
      number: this.number ? '' + this.number : null,
      companyId: this.companyId !== null && this.companyId !== undefined ? +this.companyId : null,
      companyName: this.companyName ? '' + this.companyName : null,
      companyVatNumber: this.companyVatNumber ? '' + this.companyVatNumber : null,
      companyAddressStreet: this.companyAddressStreet ? '' + this.companyAddressStreet : null,
      companyAddressNumber: this.companyAddressNumber ? '' + this.companyAddressNumber : null,
      companyAddressZip: this.companyAddressZip ? '' + this.companyAddressZip : null,
      companyAddressCity: this.companyAddressCity ? '' + this.companyAddressCity : null,
      companyAddressCounty: this.companyAddressCounty ? '' + this.companyAddressCounty : null,
      companyAddressCountryId: this.companyAddressCountryId !== null && this.companyAddressCountryId !== undefined ? +this.companyAddressCountryId : null,
      relationId: this.relationId !== null && this.relationId !== undefined ? +this.relationId : null,
      relationName: this.relationName ? '' + this.relationName : null,
      relationVatNumber: this.relationVatNumber ? '' + this.relationVatNumber : null,
      relationAddressStreet: this.relationAddressStreet ? '' + this.relationAddressStreet : null,
      relationAddressNumber: this.relationAddressNumber ? '' + this.relationAddressNumber : null,
      relationAddressZip: this.relationAddressZip ? '' + this.relationAddressZip : null,
      relationAddressCity: this.relationAddressCity ? '' + this.relationAddressCity : null,
      relationAddressCounty: this.relationAddressCounty ? '' + this.relationAddressCounty : null,
      relationAddressCountryId: this.relationAddressCountryId !== null && this.relationAddressCountryId !== undefined ? +this.relationAddressCountryId : null,
      value: this.value !== null && this.value !== undefined ? +this.value : null,
      vatValue: this.vatValue !== null && this.value !== undefined ? +this.vatValue : null,
      valueIncl: this.valueIncl !== null && this.valueIncl !== undefined ? +this.valueIncl : null,
      intnote: this.intnote ? '' + this.intnote : null,
      note: this.note ? '' + this.note : null,
      date: SetDateScalar(this.date ? moment(this.date)
        .toDate() : null),
      enddate: SetDateScalar(this.enddate ? moment(this.enddate)
        .toDate() : null),
      status: this.status ? +this.status : null,
      paymentReference: this.paymentReference ? '' + this.paymentReference : null,
      journalId: this.journalId !== null && this.journalId !== undefined ? +this.journalId : null,
      paymentBic: (this.paymentBic ?? '').length > 0 ? '' + this.paymentBic : null,
      paymentIban: (this.paymentIban ?? '').length > 0 ? '' + this.paymentIban : null,
      vatCode: this.vatCode ? '' + this.vatCode : null,
      accountNumberId: this.accountNumberId ? +this.accountNumberId : null,
    };
  }

  public createMutationInput(sublines: MutationInputObject<PurchaseInvoiceSubline>, documents: MutationInputObject<Document>, transactions: MutationInputObject<Transaction>, originalReception: PurchaseInvoice = null): IPurchaseInvoiceMutationInput {
    let mutationInput: IPurchaseInvoiceMutationInput = {id: this.id, purchaseInvoice: null, sublines: null, documents: null, transactions: null};
    mutationInput.purchaseInvoice = _.isEqual(this.convertToInput(), originalReception?.convertToInput()) ? null : this.convertToInput(); /* Check if reception is updated */

    /* Set sublines */
    mutationInput.sublines = {
      deletes: sublines.deletes.map((x) => Number(x)),
      updates: sublines.updates.map((x) => {
        return {
          id: x.id,
          subline: x.convertToInput(),
        } as IPurchaseInvoiceSublineMutationInput;
      }),
    };

    /* Set documents */
    mutationInput.documents = {
      deletes: documents.deletes.map((x) => Number(x)),
      updates: documents.updates.map((x) => {
        return {
          id: x.id,
          document: x.convertToInput(),
        } as IDocumentMutationInput;
      }),
    };

    /* Set transactions */
    mutationInput.transactions = {
      deletes: transactions.deletes.map((x) => Number(x)),
      updates: transactions.updates.map((x) => {
        return {
          id: x.id,
          transaction: x.convertToInput(),
        } as ITransactionMutationInput;
      }),
    };

    return mutationInput;
  }
}

export class PurchaseInvoiceSubline implements IPurchaseInvoiceSubline {
  id: number = 0;
  subscriberId: number = 0;
  receptionId: number = 0;
  reception: PurchaseInvoice;

  articleTypeId: number = 0;

  get ArtType(): string {
    switch (this.articleTypeId) {
      case ArticleTypeEnum.PRODUCT:
        return translate(marker('label.article.product', 'product'));
      case ArticleTypeEnum.SERVICE:
        return translate(marker('label.article.service', 'Dienst'));
    }
  }

  get ArtTypeButton(): string {
    switch (this.articleTypeId) {
      case ArticleTypeEnum.PRODUCT:
        return translate(marker('label.article.product', 'product'));
      case ArticleTypeEnum.SERVICE:
        return translate(marker('label.article.service', 'Dienst'));
    }
  }

  get ArtTypeSku(): string {
    switch (this.articleTypeId) {
      case ArticleTypeEnum.PRODUCT:
        return translate(marker('label.product.sku', 'Sku'));
      case ArticleTypeEnum.SERVICE:
        return translate(marker('label.service.sku', 'Sku'))
    }
  }

  get ArtTypeName(): string {
    switch (this.articleTypeId) {
      case ArticleTypeEnum.PRODUCT:
        return translate(marker('label.product.name', 'Naam'));
      case ArticleTypeEnum.SERVICE:
        return translate(marker('label.service.name', 'Naam'))
    }
  }

  articleId: number = 0;
  stockId: number = 0;
  articleSku: string = '';
  articleName: string = '';
  article: Product;

  amount: number = 0;
  articleSerialNumber: string = '';
  unitPrice: number = 0;
  price: number = 0;
  priceIncl: number = 0;
  vatprice: number = 0;
  note: string = '';
  unit: string = '';

  articleWarranty: number = 0;

  get Warranty(): string {
    if (this.articleWarranty == null || this.articleWarranty < 0) {
      return '';
    }

    let quotient = Math.floor(this.articleWarranty / 12);
    let remainder = this.articleWarranty % 12;

    return remainder === 0
      ? quotient === 1
        ? `${this.articleWarranty / 12} ${translate(marker('label.warranty.year', 'Jaar'))
          .toLowerCase()}`
        : `${this.articleWarranty / 12} ${translate(marker('label.warranty.years', 'Jaren'))
          .toLowerCase()}`
      : this.articleWarranty == 1
        ? `${this.articleWarranty} ${translate(marker('label.warranty.month', 'Maand'))
          .toLowerCase()}`
        : `${this.articleWarranty} ${translate(marker('label.warranty.months', 'Maanden'))
          .toLowerCase()}`;
  }

  vatCode: string = "";
  accountNumber: AccountNumber;
  accountNumberId: number = 0;

  created: string = "";
  private _created: Date;

  get Created() {return this._created;}

  set Created(date: Date) {
    this._created = date;
    this.created = SetDateTimeScalar(date);
  }

  updated: string = "";
  private _updated: Date;

  get Updated() {return this._updated;}

  set Updated(date: Date) {
    this._updated = date;
    this.updated = SetDateTimeScalar(date);
  }

  order: number = 0;

  get Valid(): boolean {
    return (this.articleName ?? ('' as string)).trim().length > 0 && this.amount !== null;
  }

  get writeOffPurchaseInformation() {
    return `${this.reception.number} | ${this.reception.relationName} | ${this.articleName}`;
  }

  get VatName() {
    return VatService.getLabel(this.vatCode);
  }

  constructor(subline: IPurchaseInvoiceSubline = null) {
    if (subline === null) {
      return;
    }

    constructFromInterface(this, subline);

    if (subline.created) {this._created = GetDateTimeScalar(subline.created)}
    if (subline.updated) {this._updated = GetDateTimeScalar(subline.updated)}
    if (subline.reception) {
      this.reception = new PurchaseInvoice(subline.reception);
    }
    if (this.articleTypeId == ArticleTypeEnum.PRODUCT && subline.article) {
      this.article = new Product(subline.article);
    }
    if (subline.accountNumber) {
      this.accountNumber = new AccountNumber(subline.accountNumber);
    }
  }

  public static new(): PurchaseInvoiceSubline {
    return new PurchaseInvoiceSubline({
      id: null,
      subscriberId: null,
      receptionId: null,
      reception: null,
      articleTypeId: ArticleTypeEnum.PRODUCT,
      articleId: null,
      stockId: null,
      articleSku: null,
      articleName: null,
      article: null,
      amount: 1,
      articleSerialNumber: null,
      unitPrice: 0,
      price: 0,
      priceIncl: 0,
      vatprice: 0,
      note: null,
      articleWarranty: null,
      vatCode: null,
      accountNumber: null,
      accountNumberId: null,
      created: null,
      updated: null,
      order: null,
      unit: null,
    });
  }

  public convertToInput(): IPurchaseInvoiceSublineInput {
    return {
      id: this.id !== null && this.id !== undefined ? +this.id : null,
      articleTypeId: this.articleTypeId !== null && this.articleTypeId !== undefined ? +this.articleTypeId : null,
      articleId: this.articleId !== null && this.articleId !== undefined ? +this.articleId : null,
      articleSku: this.articleSku ? '' + this.articleSku : null,
      articleName: this.articleName ? '' + this.articleName : null,
      vatCode: !_.isNil(this.vatCode) ? '' + this.vatCode : null,
      amount: this.amount !== null && this.amount !== undefined ? +this.amount : null,
      articleSerialNumber: this.articleSerialNumber ? '' + this.articleSerialNumber : null,
      price: this.price !== null && this.price !== undefined ? +this.price : null,
      priceIncl: this.priceIncl !== null && this.priceIncl !== undefined ? +this.priceIncl : null,
      unitPrice: this.unitPrice !== null && this.unitPrice !== undefined ? +this.unitPrice : null,
      vatprice: this.vatprice !== null && this.vatprice !== undefined ? +this.vatprice : null,
      note: this.note ? '' + this.note : null,
      articleWarranty: this.articleWarranty !== null && this.articleWarranty !== undefined ? +this.articleWarranty : null,
      accountNumberId: this.accountNumberId !== null && this.accountNumberId !== undefined ? +this.accountNumberId : null,
      order: this.order !== null && this.order !== undefined ? +this.order : null,
      unit: this.unit ? '' + this.unit : null,
    };
  }

  public calculateSublineValuesFromAmount(): void {
    this.price = +(this.price ?? 0);
    this.priceIncl = +(this.priceIncl ?? 0);
    this.vatprice = +(this.vatprice ?? 0);
    this.unitPrice = +(this.unitPrice ?? 0);
    this.amount = +(this.amount ?? 1);

    const vat = VatService.Get(this.vatCode);
    this.unitPrice = this.unitPrice ?? 0;
    this.price = +(+this.unitPrice * +(this.amount ?? 0)).toFixed(2);
    this.vatprice = +(vat.percentage ? +this.price * (+vat.percentage / 100) : 0).toFixed(2);
    this.priceIncl = +(+this.price + +(this.vatprice ?? 0)).toFixed(2);
  }

  public calculateSublineValuesFomUnitPrice(): void {
    this.price = +(this.price ?? 0);
    this.priceIncl = +(this.priceIncl ?? 0);
    this.vatprice = +(this.vatprice ?? 0);
    this.unitPrice = +(this.unitPrice ?? 0);
    this.amount = +(this.amount ?? 1);

    const vat = VatService.Get(this.vatCode);
    this.price = +(+this.unitPrice * +(this.amount ?? 0)).toFixed(2);
    this.vatprice = +(vat.percentage ? +this.price * (+vat.percentage / 100) : 0).toFixed(2);
    this.priceIncl = +(+this.price + +(this.vatprice ?? 0)).toFixed(2);
  }

  public calculateSublineValuesFromPurchasePrice(): void {
    // this.price = +(this.price ?? 0);
    this.priceIncl = +(this.priceIncl ?? 0);
    this.vatprice = +(this.vatprice ?? 0);
    this.unitPrice = +(this.unitPrice ?? 0);
    this.amount = +(this.amount ?? 1);

    const vat = VatService.Get(this.vatCode);
    this.unitPrice = +(+(this.amount ?? 0) == 0 ? 0 : +this.price / +this.amount).toFixed(2);
    this.vatprice = +(vat.percentage ? +this.price * (+vat.percentage / 100) : 0).toFixed(2);
    this.priceIncl = +(+this.price + +(this.vatprice ?? 0)).toFixed(2);
  }

  public calculateSublineValuesFromPurchasePriceIncl(): void {
    this.price = +(this.price ?? 0);
    // this.priceIncl = +(this.priceIncl ?? 0);
    this.vatprice = +(this.vatprice ?? 0);
    this.unitPrice = +(this.unitPrice ?? 0);
    this.amount = +(this.amount ?? 1);

    const vat = VatService.Get(this.vatCode);
    this.price = +(vat.percentage ? +this.priceIncl / (+vat.percentage / 100 + 1) : +this.priceIncl).toFixed(2);
    this.vatprice = +(vat.percentage ? +this.price * (+vat.percentage / 100) : 0).toFixed(2);
    this.unitPrice = +(+(this.amount ?? 0) == 0 ? 0 : +this.price / +this.amount).toFixed(2);
  }
}

export enum PurchaseInvoiceStatusEnum {
  CONCEPT = 1,
  UNPAID = 2,
  PARTIALLYPAID = 3,
  PAID = 4,
}
