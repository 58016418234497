import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {

  public static ConvertLanguageIDToIso(id: number): string {
    switch (+id) {
      case 1:
        return 'nl';
      case 2:
        return 'en';
      case 3:
        return 'fr';
      case 4:
        return 'de';
      default:
        return 'nl';
    }
  }
}
