import {Language} from 'app/shared/models/general/language';
import {Address} from './address';
import {EntityType} from '../parameters/entity-type';
import _ from 'lodash';
import {PrePaidService} from '../article/prepaid-service';
import {Contact} from './contact';
import {EntityBankAccount} from './entity-bank-account';
import {EntityEmail} from './entity-email';
import {EntityPhone} from './entity-phone';
import {Service} from '../article/service';
import {constructFromInterface} from 'app/core/logic/map.logic';
import {RelationParameter} from "./relation-parameter";
import {IRelation, IRelationInput} from "../../../core/graphql/generated/types";
import {GetDateTimeScalar, SetDateTimeScalar} from 'app/core/logic/date-scalars';
import {languages} from "../../data/languages";

export class Relation implements IRelation {
  id: number = 0;
  isCustomer: boolean = false;
  isSupplier: boolean = false;
  isProspect: boolean = false;
  subscriber_id: number = 0;
  number: number = 0;
  Number: string = '';
  name: string = '';
  vatNumber: string = '';
  entityTypeId: number = 0;
  languageId: number = 0;
  defaultVatCode: string = "";
  entityType: EntityType;
  website: string = '';
  paymentterms: number = 0;
  note: string = '';
  warning: string = '';
  language: Language;
  created: string = "";

  private _created: Date;

  get Created() {return this._created;}

  set Created(date: Date) {
    this._created = date;
    this.created = SetDateTimeScalar(date);
  }

  updated: string = "";
  private _updated: Date;

  get Updated() {return this._updated;}

  set Updated(date: Date) {
    this._updated = date;
    this.updated = SetDateTimeScalar(date);
  }

  archived: boolean = false;
  contacts: Contact[] = [];
  addresses: Address[] = [];
  emails: EntityEmail[] = [];
  bankAccounts: EntityBankAccount[] = [];
  phones: EntityPhone[] = [];
  prepaidServices: PrePaidService[] = [];
  isPeppolCustomer: boolean = false;
  memo: string = '';
  tags: string[] = [];
  globalSalesDiscount: number = 0;
  workprestationPrice: number = 0;
  workprestationService: Service;
  workprestationServiceId: number = 0;
  relationInvoiceReminderAutoCreate: boolean = false;
  translocationCostOverwrite: number = 0;
  parameters: RelationParameter[] = [];

  get Validation(): boolean {
    return this.name != null && this.name?.trim() != '' && ((this.isCustomer && this.entityTypeId > 0) || !this.isCustomer) && (this.isCustomer || this.isSupplier || this.isProspect);
  }

  get NameWithnumber() {
    return `${this.Number} - ${this.name} ${(this.vatNumber ?? '').length > 0 ? ' (' + this.vatNumber + ')' : ''}`;
  }

  get DefaultAddress() {
    return this.addresses.find(a => a.default);
  }

  constructor(relation: IRelation) {
    if (_.isNil(relation)) {
      return;
    }

    constructFromInterface(this, relation);

    if (relation.created) {this._created = GetDateTimeScalar(relation.created);}
    if (relation.updated) {this._updated = GetDateTimeScalar(relation.updated);}
    if (relation.languageId) {this.language = languages.find(l => l.id == relation.languageId);}
    if (relation.entityType) {
      this.entityType = new EntityType(relation.entityType);
    }
    if (relation.workprestationService) {
      this.workprestationService = new Service(relation.workprestationService);
    }

    if (relation.contacts?.length > 0) {
      this.contacts = relation.contacts.map((x) => new Contact(x));
    }
    if (relation.addresses?.length > 0) {
      this.addresses = relation.addresses.map((x) => new Address(x));
    }
    if (relation.emails != null && relation.emails.length > 0) {
      this.emails = relation.emails.map((x) => new EntityEmail(x));
    }
    if (relation.bankAccounts != null && relation.bankAccounts.length > 0) {
      this.bankAccounts = relation.bankAccounts.map((x) => new EntityBankAccount(x));
    }
    if (relation.phones != null && relation.phones.length > 0) {
      this.phones = relation.phones.map((x) => new EntityPhone(x));
    }
    if ((relation.prepaidServices ?? []).length > 0) {
      this.prepaidServices = relation.prepaidServices.map((x) => new PrePaidService(x));
    }
    if ((relation.parameters ?? []).length > 0) {
      this.parameters = relation.parameters.map((x) => new RelationParameter(x));
    }
  }

  public static new(): Relation {
    return new Relation({
      id: null,
      isCustomer: null,
      isSupplier: null,
      isProspect: null,
      subscriber_id: null,
      number: null,
      Number: null,
      name: null,
      vatNumber: null,
      entityTypeId: null,
      languageId: null,
      defaultVatCode: null,
      entityType: null,
      website: null,
      paymentterms: null,
      note: null,
      warning: null,
      language: null,
      created: null,
      updated: null,
      archived: null,
      contacts: [],
      addresses: [],
      emails: [],
      bankAccounts: [],
      phones: [],
      prepaidServices: [],
      parameters: [],
      memo: null,
      relationInvoiceReminderAutoCreate: true,
      tags: [],
      globalSalesDiscount: null,
      workprestationPrice: null,
      workprestationServiceId: null,
      workprestationService: null,
      translocationCostOverwrite: null,
      isPeppolCustomer: false,
    });
  }

  public convertToInput(): IRelationInput {
    return {
      id: +this.id,
      isCustomer: !!this.isCustomer,
      isSupplier: !!this.isSupplier,
      isProspect: !!this.isProspect,
      name: this.name,
      vatNumber: this.vatNumber,
      entityTypeId: +this.entityTypeId,
      website: this.website,
      paymentterms: !_.isNil(this.paymentterms) ? +this.paymentterms : null,
      note: this.note,
      warning: this.warning,
      languageId: +this.languageId,
      defaultVatCode: !_.isNil(this.defaultVatCode) ? '' + this.defaultVatCode : null,
      archived: !!this.archived,
      memo: this.memo,
      relationInvoiceReminderAutoCreate: !!this.relationInvoiceReminderAutoCreate,
      tags: this.tags,
      globalSalesDiscount: this.globalSalesDiscount ? +this.globalSalesDiscount : null,
      workprestationPrice: !_.isNil(this.workprestationPrice) ? +this.workprestationPrice : null,
      workprestationServiceId: !_.isNil(this.workprestationServiceId) ? +this.workprestationServiceId : null,
      translocationCostOverwrite: !_.isNil(this.translocationCostOverwrite) ? +this.translocationCostOverwrite : null,
    };
  }
}
