import {constructFromInterface} from "app/core/logic/map.logic";
import {IEntityPhone, IEntityPhoneInput} from "../../../core/graphql/generated/types";
import {GetDateTimeScalar, SetDateTimeScalar} from "../../../core/logic/date-scalars";

export class EntityPhone implements IEntityPhone {
  id: number = 0;
  subscriberId: number = 0;
  entityId: number = 0;
  phone: string = '';
  description: string = '';
  created: string = "";

  private _created: Date;

  get Created() {return this._created;}

  set Created(date: Date) {
    this._created = date;
    this.created = SetDateTimeScalar(date);
  }

  updated: string = "";
  private _updated: Date;

  get Updated() {return this._updated;}

  set Updated(date: Date) {
    this._updated = date;
    this.updated = SetDateTimeScalar(date);
  }

  isValid(): boolean {
    return this.phone && this.phone.trim().length > 0;
  }

  constructor(phone: IEntityPhone) {
    constructFromInterface(this, phone);

    if (phone.created) {this._created = GetDateTimeScalar(phone.created);}
    if (phone.updated) {this._updated = GetDateTimeScalar(phone.updated);}
  }

  convertToInput(): IEntityPhoneInput {
    return {
      id: this.id,
      entityId: this.entityId,
      phone: this.phone,
      description: this.description,
    };
  }

  static new(): EntityPhone {
    return new EntityPhone({
      created: null,
      description: null,
      phone: null,
      entityId: null,
      id: null,
      subscriberId: null,
      updated: null
    })
  }
}
