import {constructFromInterface} from "app/core/logic/map.logic";
import {IContact, IContactInput} from "../../../core/graphql/generated/types";


export class Contact implements IContact {
  id: number = 0;
  name: string = '';
  function: string = '';
  emailIsInvoice: boolean = false;
  emailIsOrder: boolean = false;
  emailIsQuote: boolean = false;
  emailIsWorkperformance: boolean = false;
  emailIsInvoiceReminder: boolean = false;
  emailUseWhenNotSelected: boolean = false;

  phone: string = '';

  get PhoneLink(): string {
    return `tel:${this.phone}`;
  }

  email: string = '';

  get EmailLink(): string {
    return `mailto:${this.email}`;
  }

  isValid(): boolean {
    return this.name != null && this.name.trim() != '';
  }

  constructor(contact: IContact = null) {
    if (contact === null) {
      return;
    }

    constructFromInterface(this, contact);
  }

  public static new(): Contact {
    return new Contact({
      id: null,
      email: null,
      function: null,
      name: null,
      phone: null,
      emailIsInvoice: true,
      emailIsOrder: true,
      emailIsQuote: true,
      emailIsWorkperformance: true,
      emailIsInvoiceReminder: true,
      emailUseWhenNotSelected: false,
    });
  }

  public convertToInput(): IContactInput {
    return {
      id: this.id ? +this.id : null,
      name: (this.name ?? '').trim().length > 0 ? this.name : null,
      function: (this.function ?? '').trim().length > 0 ? this.function : null,
      phone: (this.phone ?? '').trim().length > 0 ? this.phone : null,
      email: (this.email ?? '').trim().length > 0 ? this.email : null,
      emailIsInvoice: !!this.emailIsInvoice,
      emailIsOrder: !!this.emailIsOrder,
      emailIsQuote: !!this.emailIsQuote,
      emailIsWorkperformance: !!this.emailIsWorkperformance,
      emailIsInvoiceReminder: !!this.emailIsInvoiceReminder,
      emailUseWhenNotSelected: !!this.emailUseWhenNotSelected,
    };
  }
}
