import { Injectable } from "@angular/core";
import { Convertion } from "app/shared/backend-logic/convertion/classes/convertion.class";
import { DefaultConvertionSettings } from "app/shared/backend-logic/convertion/classes/default-convertion-settings.class";

@Injectable({
	providedIn: 'root',
})
export class ConvertionService extends Convertion {
	constructor() {
		super(DefaultConvertionSettings.default());
	}
}