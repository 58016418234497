import {constructFromInterface} from 'app/core/logic/map.logic';
import {IExactIntegration} from "../../../core/graphql/generated/types";


export class ExactIntegration implements IExactIntegration {
  id: number = 0;
  integrationExactEnabled: boolean = false;
  integrationExactDefaultPurchaseGlaCode = "";
  integrationExactDefaultSalesGlaCode = "";

  constructor(exact: IExactIntegration = null) {
    if (exact === null) {
      return;
    }
    constructFromInterface(this, exact);
  }
}
