import {Language} from 'app/shared/models/general/language';
import _ from 'lodash';
import {constructFromInterface} from 'app/core/logic/map.logic';
import {IEmailBodyTemplate, IEmailBodyTemplateInput, IEmailBodyTemplateMutationInput, IEmailFooterTemplate, IEmailFooterTemplateInput, IEmailFooterTemplateMutationInput} from "../../../core/graphql/generated/types";
import {GetDateTimeScalar, SetDateTimeScalar} from 'app/core/logic/date-scalars';
import {languages} from "../../data/languages";
import {translate} from "@jsverse/transloco";
import {marker} from "@nyffels/transloco-keys-manager/marker";


export class EmailBodyTemplate implements IEmailBodyTemplate {
  id: number = 0;
  subscriber_id: number = 0;
  user_id: number = 0;
  language_id: number = 0;
  language: Language;
  name: string = '';
  type: EmailTemplateTypeEnum = EmailTemplateTypeEnum.INVOICE;

  get Type(): string {
    if (!this.type) {
      return '';
    }

    return EmailBodyTemplate.GetType(this.type);
  }

  static GetType(type: EmailTemplateTypeEnum) {
    switch (type) {
      case EmailTemplateTypeEnum.CREDITNOTE:
        return translate(marker('label.emailBodyTemplate.type.creditnote', 'Creditnota'));
      case EmailTemplateTypeEnum.INVOICE:
        return translate(marker('label.emailBodyTemplate.type.invoice', 'Factuur'));
      case EmailTemplateTypeEnum.QUOTE:
        return translate(marker('label.emailBodyTemplate.type.quote', 'Offerte'));
      case EmailTemplateTypeEnum.INVOICEREMINDERLEVELTWO:
        return translate(marker('label.emailBodyTemplate.type.invoiceReminderLevelTwo', 'Tweede herinneringsniveau'));
      case EmailTemplateTypeEnum.ORDER:
        return translate(marker('label.emailBodyTemplate.type.order', 'Bestelbon'));
      case EmailTemplateTypeEnum.INVOICEREMINDERLEVELONE:
        return translate(marker('label.emailBodyTemplate.type.invoiceReminderLevelOne', 'Eerste herinneringsniveau'));
      case EmailTemplateTypeEnum.INVOICEREMINDERLEVELTHREE:
        return translate(marker('label.emailBodyTemplate.type.invoiceReminderLevelThree', 'Derde en laatste herinneringsniveau\n'));
      case EmailTemplateTypeEnum.PRODUCTDELIVERY:
        return translate(marker('label.emailBodyTemplate.type.productDelivery', 'Levering van een product'));
      case EmailTemplateTypeEnum.PURCHASEORDER:
        return translate(marker('label.emailBodyTemplate.type.purchaseOrder', 'Aankoop bestelling'));
      case EmailTemplateTypeEnum.WORKPERFORMANCE:
        return translate(marker('label.emailBodyTemplate.type.workperformance', 'Werk prestatie'));
    }
  }

  default: boolean = false;

  get Default(): string {
    return this.default ? translate(marker('label.base.yes', 'Ja')) : translate(marker('label.base.no', 'Nee'));
  }

  text: string = '';
  created: string = "";

  private _created: Date;

  get Created() {return this._created;}

  set Created(date: Date) {
    this._created = date;
    this.created = SetDateTimeScalar(date);
  }

  updated: string = "";
  private _updated: Date;

  get Updated() {return this._updated;}

  set Updated(date: Date) {
    this._updated = date;
    this.updated = SetDateTimeScalar(date);
  }

  constructor(template: IEmailBodyTemplate = null) {
    if (template === null) {
      return;
    }

    constructFromInterface(this, template);

    if (template.created) {this._created = GetDateTimeScalar(template.created);}
    if (template.updated) {this._updated = GetDateTimeScalar(template.updated);}
    if (template.language_id) {this.language = languages.find(l => l.id == template.language_id);}
  }

  public static new(): EmailBodyTemplate {
    return new EmailBodyTemplate({
      id: null,
      subscriber_id: null,
      user_id: null,
      language_id: null,
      language: null,
      type: null,
      name: null,
      default: false,
      text: null,
      created: null,
      updated: null,
    });
  }

  public convertToInput(): IEmailBodyTemplateInput {
    return {
      id: this.id,
      language_id: this.language_id,
      user_id: this.user_id,
      type: this.type,
      name: this.name,
      default: this.default,
      text: this.text,
    };
  }

  public createMutationInput(originalBodyTemplate: EmailBodyTemplate = null): IEmailBodyTemplateMutationInput {
    let mutationInput: IEmailBodyTemplateMutationInput = {id: this.id, emailBodyTemplate: null};
    mutationInput.emailBodyTemplate = _.isEqual(this.convertToInput(), originalBodyTemplate?.convertToInput()) ? null : this.convertToInput(); /* Check if emailBodyTemplate is updated */

    return mutationInput;
  }
}

export class EmailFooterTemplate implements IEmailFooterTemplate {
  id: number = 0;
  subscriber_id: number = 0;
  user_id: number = 0;
  language_id: number = 0;
  language: Language;
  default: boolean = false;

  get Default(): string {
    return this.default ? translate(marker('label.base.yes', 'Ja')) : translate(marker('label.base.no', 'Nee'));
  }

  name: string = '';
  text: string = '';
  created: string = "";

  private _created: Date;

  get Created() {return this._created;}

  set Created(date: Date) {
    this._created = date;
    this.created = SetDateTimeScalar(date);
  }

  updated: string = "";
  private _updated: Date;

  get Updated() {return this._updated;}

  set Updated(date: Date) {
    this._updated = date;
    this.updated = SetDateTimeScalar(date);
  }

  constructor(template: IEmailFooterTemplate = null) {
    if (template === null) {
      return;
    }

    constructFromInterface(this, template);

    if (template.language_id) {this.language = languages.find(l => l.id == template.language_id);}
  }

  public static new(): EmailFooterTemplate {
    return new EmailFooterTemplate({
      id: null,
      subscriber_id: null,
      user_id: null,
      language_id: null,
      language: null,
      default: false,
      text: null,
      name: null,
      created: null,
      updated: null,
    });
  }

  public convertToInput(): IEmailFooterTemplateInput {
    return {
      id: this.id,
      default: this.default,
      language_id: this.language_id,
      user_id: this.user_id,
      name: this.name,
      text: this.text,
    };
  }

  public createMutationInput(originalFooterTemplate: EmailFooterTemplate = null): IEmailFooterTemplateMutationInput {
    let mutationInput: IEmailFooterTemplateMutationInput = {id: this.id, emailFooterTemplate: null};
    mutationInput.emailFooterTemplate = _.isEqual(this.convertToInput(), originalFooterTemplate?.convertToInput()) ? null : this.convertToInput(); /* Check if emailFooterTemplate is updated */

    return mutationInput;
  }
}

export enum EmailTemplateTypeEnum {
  INVOICE = 1,
  ORDER = 2,
  QUOTE = 3,
  CREDITNOTE = 4,
  PURCHASEORDER = 5,
  PRODUCTDELIVERY = 6,
  INVOICEREMINDERLEVELONE = 7,
  INVOICEREMINDERLEVELTWO = 8,
  INVOICEREMINDERLEVELTHREE = 9,
  WORKPERFORMANCE = 10,
}
