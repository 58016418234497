import {IProject, IProjectInput, IProjectStatus} from "../../../core/graphql/generated/types";
import {GetDateTimeScalar, SetDateTimeScalar} from "../../../core/logic/date-scalars";
import {constructFromInterface} from "../../../core/logic/map.logic";
import _ from "lodash";

export class Project implements IProject {
  created?: string;
  description?: string = "";
  guid: string = "";
  name?: string = "";
  relationId?: number = 0;
  status?: IProjectStatus = IProjectStatus.Open;
  susbcriberId?: number = 0;
  updated?: string;
  userStatus?: string = "";

  private _created: Date;

  get Created() {return this._created;}

  set Created(date: Date) {
    this._created = date;
    this.created = SetDateTimeScalar(date);
  }

  private _updated: Date;

  get Updated() {return this._updated;}

  set Updated(date: Date) {
    this._updated = date;
    this.updated = SetDateTimeScalar(date);
  }

  get Validation(): boolean {
    return true;
    // TODO
  }

  constructor(project: IProject = null) {
    if (project === null) {
      return;
    }

    constructFromInterface(this, project);

    if (project.created) {this._created = GetDateTimeScalar(project.created);}
    if (project.updated) {this._updated = GetDateTimeScalar(project.updated);}
  }

  public static new(): Project {
    return new Project({
      name: null,
      status: IProjectStatus.Open,
      created: null,
      description: null,
      updated: null,
      userStatus: null,
      guid: null,
      relationId: null,
      susbcriberId: null,
    });
  }

  public convertToInput(): IProjectInput {
    return {
      guid: !_.isNil(this.guid) ? '' + this.guid : null,
      name: !_.isNil(this.name) ? '' + this.name : null,
      description: !_.isNil(this.description) ? '' + this.description : null,
      relationId: !_.isNil(this.relationId) ? +this.relationId : null,
      status: !_.isNil(this.status) ? this.status : null,
      userStatus: !_.isNil(this.userStatus) ? '' + this.userStatus : null
    }
  }
}
