import {constructFromInterface} from 'app/core/logic/map.logic';
import {marker} from "@nyffels/transloco-keys-manager/marker";
import {translate} from "@jsverse/transloco";
import {IDocument, IDocumentInput, IDocumentTypeEnum} from "../../../core/graphql/generated/types";
import {GetDateTimeScalar, SetDateTimeScalar} from 'app/core/logic/date-scalars';

export class Document implements IDocument {
  id: number = 0;
  subscriber_id: number = 0;
  type: IDocumentTypeEnum = IDocumentTypeEnum.Attachment;

  get Type(): string {
    switch (this.type) {
      case IDocumentTypeEnum.Attachment:
        return translate(marker('type.document.attachment', 'Bijlage'));
      case IDocumentTypeEnum.Pdf:
        return translate(marker('type.document.pdf', 'Pdf'));
      case IDocumentTypeEnum.Ubl:
        return translate(marker('type.document.ubl', 'Ubl'));
    }
  }

  name: string = '';
  raw: string = '';
  created: string = "";

  private _created: Date;

  get Created() {return this._created;}

  set Created(date: Date) {
    this._created = date;
    this.created = SetDateTimeScalar(date);
  }

  constructor(document: IDocument = null) {
    if (document === null) {
      return;
    }

    constructFromInterface(this, document);

    if (document.created) {this._created = GetDateTimeScalar(document.created);}
  }

  public hasRaw(): boolean {
    return this.raw ? true : false;
  }

  public setRaw(raw: string): void {
    this.raw = raw;
  }

  public convertToInput(): IDocumentInput {
    return {
      id: this.id,
      type: this.type,
      name: this.name,
      raw: this.raw,
    };
  }

  public static new(): Document {
    return new Document({
      id: null,
      created: null,
      name: null,
      raw: null,
      subscriber_id: null,
      type: IDocumentTypeEnum.Attachment,
    });
  }
}
