import {Partner} from './partner';
import _ from 'lodash';
import {Subscriber} from './subscriber';
import {constructFromInterface} from 'app/core/logic/map.logic';
import {HowDoYouKnowUsEnum} from 'app/feature/registration/client-registration/client-registration.component';
import {ISubscriberOnboarding, ISubscriberOnboardingInput, ISubscriberOnboardingMutationInput} from "../../../core/graphql/generated/types";
import {marker} from "@nyffels/transloco-keys-manager/marker";
import {translate} from "@jsverse/transloco";
import {GetDateTimeScalar, SetDateScalar, SetDateTimeScalar} from "../../../core/logic/date-scalars";


export class SubscriberOnboarding implements ISubscriberOnboarding {
  id: number = 0;
  name: string = '';
  email: string = '';
  date: string = "";
  private _date: Date;
  autoRenewLicense = false;
  autoRenewLicensePeriod = 12;

  get Date() {return this._date;}

  set Date(date: Date) {
    this._date = date;
    this.date = SetDateTimeScalar(date);
  }

  expireDate: string = "";
  private _expireDate: Date;

  get ExpireDate() {return this._expireDate;}

  set ExpireDate(date: Date) {
    this._expireDate = date;
    this.expireDate = SetDateTimeScalar(date);
  }

  token: string = '';
  used: boolean = false;
  usedDate: string = "";
  private _usedDate: Date;

  get UsedDate() {return this._usedDate;}

  set UsedDate(date: Date) {
    this._usedDate = date;
    this.usedDate = SetDateTimeScalar(date);
  }

  resellerId: number = 0;
  reseller: Partner;
  accountancyId: number = 0;
  accountancy: Partner;
  subscriberId: number = 0;
  subscriber: Subscriber;
  howDoYouKnowUs: HowDoYouKnowUsEnum = HowDoYouKnowUsEnum.OTHER;
  howDoYouKnowUsExtra: string = '';
  referral: string = '';

  get Validation(): boolean {
    return (this.name ?? '').trim().length > 0 && (this.email ?? '').trim().length > 0;
  }

  get IsExpired(): boolean {
    return new Date() > new Date(this.expireDate);
  }

  get HowDoYouKnowUs() {
    switch (this.howDoYouKnowUs) {
      case HowDoYouKnowUsEnum.OTHER: {
        return translate(marker('howdoyouknowus.onboarding.other', 'Andere'));
      }
      case HowDoYouKnowUsEnum.FRIEND: {
        return translate(marker('howdoyouknowus.onboarding.friends', 'Via vrienden'));
      }
      case HowDoYouKnowUsEnum.ACCOUNTANT: {
        return translate(marker('howdoyouknowus.onboarding.accountant', 'Via mijn boekhouder / accountant'));
      }
      case HowDoYouKnowUsEnum.SOCIALMEDIA: {
        return translate(marker('howdoyouknowus.onboarding.socialmedia', 'Via sociale media'));
      }
      case HowDoYouKnowUsEnum.GOOGLE: {
        return translate(marker('howdoyouknowus.onboarding.google', 'Via google'));
      }
      case HowDoYouKnowUsEnum.OFFLINEADVERTISEMENT: {
        return translate(marker('howdoyouknowus.onboarding.offlineadds', 'Advertenties (offline)'));
      }
      default: {
        return translate(marker('howdoyouknowus.onboarding.unknown', 'Onbekend'));
      }
    }
  }

  constructor(onboarding: ISubscriberOnboarding = null) {
    if (onboarding === null) {
      return;
    }

    constructFromInterface(this, onboarding);

    if (onboarding.date) {this._date = GetDateTimeScalar(onboarding.date);}
    if (onboarding.expireDate) {this._expireDate = GetDateTimeScalar(onboarding.expireDate);}
    if (onboarding.usedDate) {this._usedDate = GetDateTimeScalar(onboarding.usedDate);}
  }

  public static new(): SubscriberOnboarding {
    return new SubscriberOnboarding({
      id: null,
      name: null,
      email: null,
      date: SetDateScalar(new Date()),
      expireDate: null,
      token: null,
      used: false,
      usedDate: null,
      howDoYouKnowUs: null,
      howDoYouKnowUsExtra: null,
      referral: null,
    });
  }

  public convertToInput(expirationPeriod: number): ISubscriberOnboardingInput {
    return {
      email: this.email,
      id: +this.id,
      expirationPeriod: +expirationPeriod,
      name: this.name,
      autoRenewLicense: !!this.autoRenewLicense,
      autoRenewLicensePeriod: this.autoRenewLicense ? +this.autoRenewLicensePeriod : null
    };
  }

  public createMutationInput(expirationPeriod: number, originalExpirationPeriod: number, originalOnboarding: SubscriberOnboarding = null): ISubscriberOnboardingMutationInput {
    let mutationInput: ISubscriberOnboardingMutationInput = {id: this.id, onboarding: null};
    mutationInput.onboarding = _.isEqual(this.convertToInput(expirationPeriod), originalOnboarding?.convertToInput(originalExpirationPeriod)) ? null : this.convertToInput(expirationPeriod);

    return mutationInput;
  }
}
