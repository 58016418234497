import {IDocumentRaw} from "../../../core/graphql/generated/types";
import {constructFromInterface} from "../../../core/logic/map.logic";

export class DocumentRaw implements IDocumentRaw {
  public guid: string = "";
  public raw: string = "";
  public mime: string = "";
  public size: number = 0;

  constructor(raw: IDocumentRaw) {
    constructFromInterface(this, raw);
  }
}
