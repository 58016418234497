import {constructFromInterface} from "app/core/logic/map.logic";
import {IEntityBankAccount, IEntityBankAccountInput} from "../../../core/graphql/generated/types";
import {GetDateTimeScalar, SetDateTimeScalar} from "../../../core/logic/date-scalars";

export class EntityBankAccount implements IEntityBankAccount {
  id: number = 0;
  subscriberId: number = 0;
  entityId: number = 0;
  iban: string = '';
  bic: string = '';
  description: string = '';
  created: string = "";

  private _created: Date;

  get Created() {return this._created;}

  set Created(date: Date) {
    this._created = date;
    this.created = SetDateTimeScalar(date);
  }

  updated: string = "";
  private _updated: Date;

  get Updated() {return this._updated;}

  set Updated(date: Date) {
    this._updated = date;
    this.updated = SetDateTimeScalar(date);
  }

  isValid(): boolean {
    return this.iban && this.iban.trim().length > 0;
  }

  constructor(bankAccount: IEntityBankAccount = null) {
    if (bankAccount === null) {
      return;
    }

    constructFromInterface(this, bankAccount);

    if (bankAccount.created) {this._created = GetDateTimeScalar(bankAccount.created);}
    if (bankAccount.updated) {this._updated = GetDateTimeScalar(bankAccount.updated);}
  }

  convertToInput(): IEntityBankAccountInput {
    return {
      id: this.id,
      entityId: this.entityId,
      iban: this.iban,
      bic: this.bic,
      description: this.description,
    };
  }

  static new(): EntityBankAccount {
    return new EntityBankAccount({
      created: null,
      description: null,
      iban: null,
      bic: null,
      entityId: null,
      id: null,
      subscriberId: null,
      updated: null
    })
  }
}
