import _ from 'lodash';
import moment from 'moment-timezone';
import {Creditnote} from '../document/creditnote';
import {Invoice} from '../document/invoice';
import {PurchaseCreditnote} from '../document/purchase-creditnote';
import {PurchaseInvoice} from '../document/purchase-invoice';
import {MutationInputObject} from 'app/shared/models/system/mutation-object';
import {User} from '../entity/user';
import {Subscriber} from '../entity/subscriber';
import {constructFromInterface} from 'app/core/logic/map.logic';
import {ICreditnote, IInvoice, ILedgerExport, ILedgerExportInput, ILedgerExportMutationInput, ILedgerExportParameter, ILedgerExportParameterInput, ILedgerExportParameterMutationInput, ILedgerExportSubline, IPurchaseCreditnote, IPurchaseInvoice} from "../../../core/graphql/generated/types";
import {GetDateScalar, GetDateTimeScalar, SetDateScalar, SetDateTimeScalar} from "../../../core/logic/date-scalars";

export enum LedgerExportSublineDocumentTypeEnum {
  SALESINVOICE = 1,
  SALESCREDITNOTE = 2,
  PURCHASEINVOICE = 3,
  PURCHASECREDITNOTE = 4,
}

export class LedgerExport implements ILedgerExport {
  id: number = 0;
  subscriberId: number = 0;
  subscriber: Subscriber;
  userId: number = 0;
  userName: string = '';
  user: User;
  type: LedgerExportTypeEnum = LedgerExportTypeEnum.PDF;
  start: string = "";
  private _start: Date;

  get Start() {return this._start;}

  set Start(date: Date) {
    this._start = date;
    this.start = SetDateScalar(date);
  }

  end: string = "";
  private _end: Date;

  get End() {return this._end;}

  set End(date: Date) {
    this._end = date;
    this.end = SetDateScalar(date);
  }

  salesInvoices: boolean = false;
  salesCreditnotes: boolean = false;
  purchaseInvoices: boolean = false;
  purchaseCreditnotes: boolean = false;
  created: string = "";

  private _created: Date;

  get Created() {return this._created;}

  set Created(date: Date) {
    this._created = date;
    this.created = SetDateTimeScalar(date);
  }

  started: string = "";
  private _started: Date;

  get Started() {return this._started;}

  set Started(date: Date) {
    this._started = date;
    this.started = SetDateTimeScalar(date);
  }

  completed: string = "";
  private _completed: Date;

  get Completed() {return this._completed;}

  set Completed(date: Date) {
    this._completed = date;
    this.completed = SetDateTimeScalar(date);
  }

  failed: string = "";
  private _failed: Date;

  get Failed() {return this._failed;}

  set Failed(date: Date) {
    this._failed = date;
    this.failed = SetDateTimeScalar(date);
  }

  zipDownloaded: string = "";
  private _zipDownloaded: Date;

  get ZipDownloaded() {return this._zipDownloaded;}

  set ZipDownloaded(date: Date) {
    this._zipDownloaded = date;
    this.zipDownloaded = SetDateTimeScalar(date);
  }

  name: string = '';

  parameters: LedgerExportParameter[] = [];
  sublines: LedgerExportSubline[] = [];

  get Validation(): boolean {
    return moment(this.end)
      .isValid();
  }

  constructor(ledgerExport: ILedgerExport = null) {
    if (ledgerExport === null) {
      return;
    }

    constructFromInterface(this, ledgerExport);

    if (ledgerExport.start) {this._start = GetDateScalar(ledgerExport.start);}
    if (ledgerExport.end) {this._end = GetDateScalar(ledgerExport.end);}
    if (ledgerExport.created) {this._created = GetDateTimeScalar(ledgerExport.created);}
    if (ledgerExport.started) {this._started = GetDateTimeScalar(ledgerExport.started);}
    if (ledgerExport.completed) {this._completed = GetDateTimeScalar(ledgerExport.completed);}
    if (ledgerExport.failed) {this._failed = GetDateTimeScalar(ledgerExport.failed);}
    if (ledgerExport.zipDownloaded) {this._zipDownloaded = GetDateTimeScalar(ledgerExport.zipDownloaded);}
    if (ledgerExport.subscriber) {
      this.subscriber = new Subscriber(ledgerExport.subscriber);
    }
    if (ledgerExport.user) {
      this.user = new User(ledgerExport.user);
    }

    if (ledgerExport.parameters) {
      this.parameters = ledgerExport.parameters.map((param) => new LedgerExportParameter(param));
    }
    if (ledgerExport.sublines) {
      this.sublines = ledgerExport.sublines.map((subline) => new LedgerExportSubline(subline));
    }
  }

  public static new(): LedgerExport {
    return new LedgerExport({
      id: null,
      subscriberId: null,
      subscriber: null,
      userId: null,
      userName: null,
      user: null,
      type: LedgerExportTypeEnum.PDF,
      start: null,
      end: SetDateScalar(new Date()),
      salesInvoices: true,
      salesCreditnotes: true,
      purchaseInvoices: true,
      purchaseCreditnotes: true,
      created: null,
      started: null,
      completed: null,
      failed: null,
      parameters: [],
      zipDownloaded: null,
      sublines: [],
      name: '',
      __typename: null,
    });
  }

  public convertToInput(): ILedgerExportInput {
    return {
      id: this.id,
      name: this.name,
      type: this.type,
      start: SetDateScalar(this.start ? moment(this.start)
        .toDate() : null),
      end: SetDateScalar(this.end ? moment(this.end)
        .toDate() : null),
      salesInvoices: this.salesInvoices,
      salesCreditnotes: this.salesCreditnotes,
      purchaseInvoices: this.purchaseInvoices,
      purchaseCreditnotes: this.purchaseCreditnotes,
    };
  }

  public createMutationInput(parameters: MutationInputObject<LedgerExportParameter>, originalExport: LedgerExport = null): ILedgerExportMutationInput {
    let mutationInput: ILedgerExportMutationInput = {id: this.id, export: null, parameters: null};
    mutationInput.export = _.isEqual(this.convertToInput(), originalExport?.convertToInput()) ? null : this.convertToInput();

    mutationInput.parameters = {
      deletes: parameters.deletes.map((x) => +x),
      updates: parameters.updates.map((x) => {
        return {
          id: +x.id,
          parameter: x.convertToInput(),
        } as ILedgerExportParameterMutationInput;
      }),
    };

    return mutationInput;
  }
}

export class LedgerExportSubline implements ILedgerExportSubline {
  id: number = 0;
  exportId: number = 0;
  subscriberId: number = 0;
  documentTypeId: LedgerExportSublineDocumentTypeEnum = LedgerExportSublineDocumentTypeEnum.SALESINVOICE;
  documentId: number = 0;
  document: Invoice | Creditnote | PurchaseInvoice | PurchaseCreditnote;
  completed: string = "";
  private _completed: Date;

  get Completed() {return this._completed;}

  set Completed(date: Date) {
    this._completed = date;
    this.completed = SetDateTimeScalar(date);
  }

  failed: string = "";
  private _failed: Date;

  get Failed() {return this._failed;}

  set Failed(date: Date) {
    this._failed = date;
    this.failed = SetDateTimeScalar(date);
  }

  failedData: string = '';

  constructor(subline: ILedgerExportSubline = null) {
    if (subline === null) {
      return;
    }

    constructFromInterface(this, subline);
    if (subline.completed) {this._completed = GetDateTimeScalar(subline.completed);}
    if (subline.failed) {this._failed = GetDateTimeScalar(subline.failed);}
    if (subline.document) {
      switch (subline.document.__typename) {
        case 'Invoice':
          this.document = new Invoice(subline.document as IInvoice);
          break;
        case 'Creditnote':
          this.document = new Creditnote(subline.document as ICreditnote);
          break;
        case 'PurchaseInvoice':
          this.document = new PurchaseInvoice(subline.document as IPurchaseInvoice);
          break;
        case 'PurchaseCreditnote':
          this.document = new PurchaseCreditnote(subline.document as IPurchaseCreditnote);
          break;
        default:
          this.document = null;
          break;
      }
    }
  }
}

export class LedgerExportParameter implements ILedgerExportParameter {
  id: number = 0;
  exportId: number = 0;
  key: string = '';
  value: string = '';

  constructor(parameter: ILedgerExportParameter = null) {
    if (parameter === null) {
      return;
    }

    constructFromInterface(this, parameter);
  }

  public static new(): LedgerExportParameter {
    return new LedgerExportParameter({
      id: null,
      exportId: null,
      key: null,
      value: null,
    });
  }

  public convertToInput(): ILedgerExportParameterInput {
    return {
      id: this.id,
      key: this.key,
      value: this.value,
    };
  }

  public createMutationInput(originalParameter: LedgerExportParameter = null): ILedgerExportParameterMutationInput {
    let mutationInput: ILedgerExportParameterMutationInput = {id: this.id, parameter: null};
    mutationInput.parameter = _.isEqual(this.convertToInput(), originalParameter?.convertToInput()) ? null : this.convertToInput();

    return mutationInput;
  }
}

export enum LedgerExportTypeEnum {
  PDF = 1,
  OCTOPUS = 2,
  ADMISOL = 3,
  YUKI = 4,
  EXACT = 5,
  MANUAL = 6
}
