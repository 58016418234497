import {Vats} from "../../shared/data/vat";
import {translate} from "@jsverse/transloco";
import {marker} from "@nyffels/transloco-keys-manager/marker";
import {Injectable} from "@angular/core";
import {AuthService} from "./auth.service";

@Injectable({
  providedIn: 'root',
})
export class VatService {
  constructor(private authService: AuthService) {}

  public static Get(code: string) {
    return Vats.find(v => v.code === code);
  }

  public List(showObsolete: boolean = false) {
    let vats = Vats;
    if (!showObsolete) {
      vats = vats.filter(v => !v.obsolete);
    }
    if (this.authService.activeSubscriberSnapshot) {
      vats = vats.filter(v => v.countryId == this.authService.activeSubscriberSnapshot.countryId);
    }
    return vats;
  }

  public static getLabel(code: string) {
    switch(code) {
      case "BE-INTRA-0":
        return translate(marker('vats.label.be-intra-0.label', 'Intracommunautair / BTW verlegd'));
      case "BE-MC-0":
        return translate(marker('vats.label.be-mc-0.label', 'Medecontractant'));
      case "BE-REN-6":
        return translate(marker('vats.label.be-ren-6.label', '6% Renovatie'));
      case "BE-VAT-0":
        return translate(marker('vats.label.be-vat-0.label', 'Vrij van BTW'));
      case "BE-VAT-6":
        return translate(marker('vats.label.be-vat-6.label', '6%'));
      case "BE-VAT-12":
        return translate(marker('vats.label.be-vat-12.label', '12%'));
      case "BE-VAT-21":
        return translate(marker('vats.label.be-vat-21.label', '21%'));
      default:
        return translate(marker('vats.label.unknown', 'Onbekend BTW tarief. Neem contact op met support!'));
    }
  }

  public getByPercentage(percentage: number) {
    switch (this.authService.activeSubscriberSnapshot.countryId) {
      default:
        switch (percentage) {
          case 0:
            return "BE-VAT-0";
          case 6:
            return "BE-VAT-6";
          case 12:
            return "BE-VAT-12";
          case 21:
            return "BE-VAT-21";
        }
    }
  }

  public getIntra() {
    switch (this.authService.activeSubscriberSnapshot.countryId) {
      default:
        return "BE-INTRA-0";
    }
  }
}
