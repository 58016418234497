import {Country} from 'app/shared/models/general/country';
import {constructFromInterface} from 'app/core/logic/map.logic';
import {IEntityVatCheck} from "../../../core/graphql/generated/types";
import {countries} from "../../data/country";


export class EntityVatCheck implements IEntityVatCheck {
  valid: boolean = false;
  countryCode: string = '';
  vatNumber: string = '';

  get VatNumber(): string {
    return this.countryCode + this.vatNumber;
  }

  name: string = '';
  address: { street: string; number: string; zip_code: string; city: string; countryCode: string; country: Country };

  get FullAddress(): string {
    return `${this.address.street} ${this.address.number}, ${this.address.zip_code} ${this.address.city}, ${this.address.country.getName()}`;
  }

  constructor(entityVatCheck: IEntityVatCheck = null) {
    if (entityVatCheck === null) {
      return;
    }

    constructFromInterface(this, entityVatCheck);

    this.address = {} as { street: string; number: string; zip_code: string; city: string; countryCode: string; country: Country };
    this.address.street = entityVatCheck.address.street;
    this.address.number = entityVatCheck.address.number;
    this.address.zip_code = entityVatCheck.address.zip_code;
    this.address.city = entityVatCheck.address.city;
    this.address.countryCode = entityVatCheck.address.countryCode;
    if (entityVatCheck.address?.countryCode) {
      this.address.country = new Country(countries.find(c => c.alpha.toLowerCase() == (entityVatCheck.address.countryCode ?? '').toLowerCase()));
    }
  }
}
