import {Service} from 'app/shared/models/article/service';
import {Translocation} from 'app/shared/models/general/translocation';
import _ from 'lodash';
import {Relation} from '../relation/relation.class';
import {Company} from '../entity/company';
import moment from 'moment-timezone';
import {Invoice} from './invoice';
import {ArticleTypeEnum} from '../article/articletype.enum';
import {Product} from '../article/product';
import {PrePaidServiceTransaction} from '../article/prepaid-service';
import {CombinedArticle} from '../article/combinedarticle';
import {User} from '../entity/user';
import {Document} from '../general/document';
import {Email} from '../mail/email';
import {constructFromInterface} from 'app/core/logic/map.logic';
import {ICombinedArticle, IProduct, IService, IWorkPerformance, IWorkPerformanceExtra, IWorkPerformanceExtraInput, IWorkPerformanceInput, IWorkPerformanceUser, IWorkPerformanceUserInput} from "../../../core/graphql/generated/types";
import {GetDateScalar, GetDateTimeScalar, SetDateScalar, SetDateTimeScalar} from "../../../core/logic/date-scalars";
import {translate} from "@jsverse/transloco";
import {marker} from "@nyffels/transloco-keys-manager/marker";

export class WorkPerformance implements IWorkPerformance {
  id: number = 0;
  subscriberId: number = 0;
  userId: number = 0;
  user: User;
  companyId: number = 0;
  company: Company;
  relationId: number = 0;
  relation: Relation;
  time: number = 0;
  break: number = 0;
  date: string = "";
  private _date: Date;

  get Date() {return this._date;}

  set Date(date: Date) {
    this._date = date;
    this.date = SetDateScalar(date);
  }

  startDate: string = "";
  private _startDate: Date;

  get StartDate() {return this._startDate;}

  set StartDate(date: Date) {
    this._startDate = date;
    this.startDate = SetDateTimeScalar(date);
  }

  endDate: string = "";
  private _endDate: Date;

  get EndDate() {return this._endDate;}

  set EndDate(date: Date) {
    this._endDate = date;
    this.endDate = SetDateTimeScalar(date);
  }

  internalNote: string = '';
  note: string = '';
  serviceId: number = 0;
  service: Service;
  price: number = 0;
  invoiceId: number = 0;
  invoice: Invoice;
  prepaidServiceTransactionId: number = 0;
  prepaidServiceTransaction: PrePaidServiceTransaction;
  created: string = "";
  private _created: Date;

  get Created() {return this._created;}

  set Created(date: Date) {
    this._created = date;
    this.created = SetDateTimeScalar(date);
  }

  updated: string = "";
  private _updated: Date;

  get Updated() {return this._updated;}

  set Updated(date: Date) {
    this._updated = date;
    this.updated = SetDateTimeScalar(date);
  }

  signature: string = '';
  tags: string[] = [];

  document: Document;
  translocations: Translocation[] = [];
  users: WorkPerformanceUser[] = [];
  extras: WorkPerformanceExtra[] = [];
  emails: Email[] = [];

  isValid(time: boolean = false): boolean {
    return this.date && this.userId && (!time || (time && moment(this.startDate)
      .isValid()));
  }

  get expectedPriceBruto() {
    const time = +this.time / 60;
    return +(this.price * time);
  }

  get extrasPrice(): number {
    return (this.extras ?? []).length > 0 ? _.sumBy(this.extras, (extra) => extra.price) : 0;
  }

  get usersPrice(): number {
    const time = +this.time / 60;
    return (this.users ?? []).length > 0 ? _.sumBy(this.users, (user) => (user.price ?? 0) * time) : 0;
  }

  get expectedPrice(): number {
    const time = +this.time / 60;

    const extrasPrice = (this.extras ?? []).length > 0 ? _.sumBy(this.extras, (extra) => extra.price) : 0;
    const usersPrice = (this.users ?? []).length > 0 ? _.sumBy(this.users, (user) => (user.price ?? 0) * time) : 0;
    return +(+this.price * time + extrasPrice + usersPrice);
  }

  constructor(workperformance: IWorkPerformance = null) {
    if (workperformance === null) {
      return;
    }

    constructFromInterface(this, workperformance);

    if (workperformance.date) {
      this._date = GetDateScalar(workperformance.date);
    }
    if (workperformance.startDate) {
      this._startDate = GetDateScalar(workperformance.startDate);
    }
    if (workperformance.endDate) {
      this._endDate = GetDateScalar(workperformance.endDate);
    }
    if (workperformance.created) {
      this._created = GetDateTimeScalar(workperformance.created);
    }
    if (workperformance.updated) {
      this._updated = GetDateTimeScalar(workperformance.updated);
    }
    if (workperformance.relation) {
      this.relation = new Relation(workperformance.relation);
    }
    if (workperformance.company) {
      this.company = new Company(workperformance.company);
    }
    if (workperformance.user) {
      this.user = new User(workperformance.user);
    }
    if (workperformance.service) {
      this.service = new Service(workperformance.service);
    }
    if (workperformance.invoice) {
      this.invoice = new Invoice(workperformance.invoice);
    }
    if (workperformance.prepaidServiceTransaction) {
      this.prepaidServiceTransaction = new PrePaidServiceTransaction(workperformance.prepaidServiceTransaction);
    }
    if (workperformance.translocations) {
      this.translocations = workperformance.translocations.map((translocation) => new Translocation(translocation));
    }
    if (workperformance.extras) {
      this.extras = workperformance.extras.map((extra) => new WorkPerformanceExtra(extra));
    }
    if (workperformance.users) {
      this.users = workperformance.users.map((user) => new WorkPerformanceUser(user));
    }
    if (workperformance.document) {
      this.document = new Document(workperformance.document);
    }
    if (workperformance.emails) {
      this.emails = workperformance.emails.map((email) => new Email(email));
    }
  }

  public static new(): WorkPerformance {
    return new WorkPerformance({
      id: null,
      subscriberId: null,
      userId: null,
      relationId: null,
      companyId: null,
      company: null,
      time: null,
      break: null,
      date: SetDateScalar(new Date()),
      startDate: null,
      endDate: null,
      internalNote: null,
      note: null,
      serviceId: null,
      price: null,
      invoiceId: null,
      prepaidServiceTransactionId: null,
      created: null,
      updated: null,
      user: null,
      relation: null,
      service: null,
      invoice: null,
      prepaidServiceTransaction: null,
      translocations: [],
      emails: [],
      extras: [],
      users: [],
      signature: null,
      tags: [],
      document: null,
    });
  }

  public convertToInput(): IWorkPerformanceInput {
    return {
      id: +this.id,
      userId: +this.userId,
      companyId: +this.companyId,
      relationId: +this.relationId,
      time: +this.time,
      break: +this.break,
      date: SetDateScalar(this.date ? moment(this.date)
        .toDate() : null),
      startDate: SetDateTimeScalar(this.startDate ? moment(this.startDate)
        .utc(false)
        .toDate() : null),
      endDate: SetDateTimeScalar(this.endDate ? moment(this.endDate)
        .utc(false)
        .toDate() : null),
      internalNote: this.internalNote,
      note: this.note,
      serviceId: this.serviceId,
      price: +this.price,
      invoiceId: this.invoiceId,
      tags: this.tags,
    };
  }
}

export class WorkPerformanceExtra implements IWorkPerformanceExtra {
  id: number = 0;
  subscriberId: number = 0;
  workperformanceId: number = 0;
  articleTypeId: ArticleTypeEnum = ArticleTypeEnum.PRODUCT;

  get ArtType(): string {
    switch (this.articleTypeId) {
      case ArticleTypeEnum.PRODUCT:
        return translate(marker('label.article.product', 'product'));
      case ArticleTypeEnum.SERVICE:
        return translate(marker('label.article.service', 'Dienst'));
      case ArticleTypeEnum.COMBINEDARTICLE:
        return translate(marker('label.article.combinedArticle', 'Gecombineerd artikel'));
    }
  }

  get ArtTypeButton(): string {
    switch (this.articleTypeId) {
      case ArticleTypeEnum.PRODUCT:
        return translate(marker('label.article.product', 'product'));
      case ArticleTypeEnum.SERVICE:
        return translate(marker('label.article.service', 'Dienst'));
      case ArticleTypeEnum.COMBINEDARTICLE:
        return translate(marker('label.article.combinedArticle', 'Gecombineerd artikel'));
    }
  }

  get ArtTypeSku(): string {
    switch (this.articleTypeId) {
      case ArticleTypeEnum.PRODUCT:
        return translate(marker('label.product.sku', 'Sku'));
      case ArticleTypeEnum.SERVICE:
        return translate(marker('label.service.sku', 'Sku'))
      case ArticleTypeEnum.COMBINEDARTICLE:
        return translate(marker('label.combinedArticle.sku', 'Sku'))
    }
  }

  get ArtTypeName(): string {
    switch (this.articleTypeId) {
      case ArticleTypeEnum.PRODUCT:
        return translate(marker('label.product.name', 'Naam'));
      case ArticleTypeEnum.SERVICE:
        return translate(marker('label.service.name', 'Naam'))
      case ArticleTypeEnum.COMBINEDARTICLE:
        return translate(marker('label.combinedArticle.name', 'Naam'))
    }
  }

  articleId: number = 0;
  articleSku: string = '';
  articleName: string = '';
  article: Product | Service | CombinedArticle;
  amount: number = 0;
  unitPrice: number = 0;
  price: number = 0;
  note: string = '';
  created: string = "";
  private _created: Date;

  get Created() {return this._created;}

  set Created(date: Date) {
    this._created = date;
    this.created = SetDateTimeScalar(date);
  }

  updated: string = "";
  private _updated: Date;

  get Updated() {return this._updated;}

  set Updated(date: Date) {
    this._created = date;
    this.created = SetDateTimeScalar(date);
  }

  unit: string = '';

  constructor(extra: IWorkPerformanceExtra = null) {
    if (extra === null) {
      return;
    }

    constructFromInterface(this, extra);

    if (extra.created) {
      this._created = GetDateTimeScalar(extra.created);
    }
    if (extra.updated) {
      this._updated = GetDateTimeScalar(extra.updated);
    }

    if (extra.article) {
      switch (extra?.articleTypeId) {
        case ArticleTypeEnum.PRODUCT:
          this.article = new Product(extra.article as IProduct);
          break;
        case ArticleTypeEnum.SERVICE:
          this.article = new Service(extra.article as IService);
          break;
        case ArticleTypeEnum.COMBINEDARTICLE:
          this.article = new CombinedArticle(extra.article as ICombinedArticle);
          break;
      }
    }
  }

  public static new(): WorkPerformanceExtra {
    return new WorkPerformanceExtra({
      id: null,
      subscriberId: null,
      workperformanceId: null,
      articleTypeId: 1,
      articleId: null,
      articleSku: null,
      articleName: null,
      article: null,
      amount: 1,
      unitPrice: 0,
      price: 0,
      note: null,
      created: null,
      updated: null,
      unit: null,
    });
  }

  public convertToInput(): IWorkPerformanceExtraInput {
    return {
      id: this.id,
      articleTypeId: +this.articleTypeId,
      articleId: this.articleId ? +this.articleId : null,
      articleSku: this.articleSku,
      articleName: this.articleName,
      amount: this.amount ? +this.amount : null,
      unitPrice: this.unitPrice ? +this.unitPrice : null,
      price: this.price ? +this.price : null,
      note: this.note,
      unit: this.unit,
    };
  }

  calculateSublineValuesFromAmount(): void {
    this.price = this.unitPrice * this.amount;
  }

  calculateSublineValuesFomUnitPrice(): void {
    this.price = this.unitPrice * this.amount;
  }

  calculateSublineValuesFromPrice(): void {
    this.unitPrice = (this.amount ?? 0) <= 0 ? 0 : this.price / this.amount;
  }
}

export class WorkPerformanceUser implements IWorkPerformanceUser {
  id: number = 0;
  subscriberId: number = 0;
  workperformanceId: number = 0;
  userId: number = 0;
  user: User;
  userName: string = '';
  serviceId: number = 0;
  service: Service;
  price: number = 0;

  constructor(user: IWorkPerformanceUser) {
    constructFromInterface(this, user);

    if (user.user) {
      this.user = new User(user.user);
    }
    if (user.service) {
      this.service = new Service(user.service);
    }
  }

  public static new(): WorkPerformanceUser {
    return new WorkPerformanceUser({
      id: null,
      subscriberId: null,
      workperformanceId: null,
      userId: null,
      user: null,
      userName: null,
      serviceId: null,
      service: null,
      price: null,
    });
  }

  convertToInput(): IWorkPerformanceUserInput {
    return {
      id: this.id !== null ? +this.id : null,
      userId: this.userId !== null ? +this.userId : null,
      userName: this.userName !== null ? '' + this.userName : null,
      serviceId: this.serviceId !== null ? +this.serviceId : null,
      price: this.price !== null ? +this.price : null,
    } as IWorkPerformanceUserInput;
  }
}
