import {TransactionGateway} from 'app/shared/models/parameters/transaction-gateway';
import moment from 'moment';
import {constructFromInterface} from 'app/core/logic/map.logic';
import {ITransaction, ITransactionInput} from "../../../core/graphql/generated/types";
import {GetDateScalar, GetDateTimeScalar, SetDateScalar, SetDateTimeScalar} from "../../../core/logic/date-scalars";

export class Transaction implements ITransaction {
  id: number = 0;
  subscriber_id: number = 0;
  gateway_id: number = 0;
  gateway_name: string = '';
  gateway: TransactionGateway;
  amount: number = 0;
  date: string = "";
  private _date: Date;

  get Date() {return this._date;}

  set Date(date: Date) {
    this._date = date;
    this.date = SetDateScalar(date);
  }

  note: string = '';
  created: string = "";

  private _created: Date;

  get Created() {return this._created;}

  set Created(date: Date) {
    this._created = date;
    this.created = SetDateTimeScalar(date);
  }

  updated: string = "";
  private _updated: Date;

  get Updated() {return this._updated;}

  set Updated(date: Date) {
    this._updated = date;
    this.updated = SetDateTimeScalar(date);
  }

  bankTransactionId: number = 0;

  get Valid(): boolean {
    return this.amount !== null && this.amount !== undefined && this.date !== null && this.date !== undefined;
  }

  constructor(transaction: ITransaction = null) {
    if (transaction === null) {
      return;
    }

    constructFromInterface(this, transaction);

    if (transaction.date) {this._date = GetDateScalar(transaction.created);}
    if (transaction.created) {this._created = GetDateTimeScalar(transaction.created);}
    if (transaction.updated) {this._updated = GetDateTimeScalar(transaction.updated);}
    if (transaction.gateway) {
      this.gateway = new TransactionGateway(transaction.gateway);
    }
  }

  public static new(): Transaction {
    return new Transaction({
      id: null,
      subscriber_id: null,
      gateway_id: null,
      gateway_name: null,
      gateway: null,
      amount: null,
      date: null,
      note: null,
      created: null,
      updated: null,
      bankTransactionId: null,
    });
  }

  public convertToInput(): ITransactionInput {
    return {
      id: this.id,
      amount: +this.amount,
      date: SetDateScalar(this.date ? moment(this.date)
        .toDate() : null),
      gateway_id: +this.gateway_id,
      gateway_name: this.gateway_name,
      note: this.note,
      bankTransactionId: this.bankTransactionId,
    };
  }
}

