import {constructFromInterface} from 'app/core/logic/map.logic';
import {IDiverseDocument, IDocumentRaw} from "../../../core/graphql/generated/types";
import {GetDateTimeScalar, SetDateTimeScalar} from "../../../core/logic/date-scalars";
import {DocumentRaw} from "../general/document-raw";

export class DiverseDocument implements IDiverseDocument {
  description?: string = "";
  filename?: string = "";
  id: string = "";
  name?: string = "";
  subscriberId?: number = 0;
  timestamp?: string = "";
  private _timestamp: Date;

  metadata: DocumentRaw;

  get Timestamp() {return this._timestamp;}

  set Timestamp(date: Date) {
    this._timestamp = date;
    this.timestamp = SetDateTimeScalar(date);
  }

  constructor(diverseDocument: IDiverseDocument) {
    constructFromInterface(this, diverseDocument);

    if (diverseDocument.timestamp) {this._timestamp = GetDateTimeScalar(diverseDocument.timestamp);}
    if (diverseDocument.metadata) {this.metadata = new DocumentRaw(diverseDocument.metadata);}
  }
}
