import _ from 'lodash';
import {Product} from './product';
import {Invoice} from '../document/invoice';
import {PurchaseInvoice} from '../document/purchase-invoice';
import {constructFromInterface} from 'app/core/logic/map.logic';
import {IStock, IStockInput, IStockMutationInput} from "../../../core/graphql/generated/types";
import {GetDateTimeScalar, SetDateTimeScalar} from "../../../core/logic/date-scalars";
import {translate} from "@jsverse/transloco";
import {marker} from "@nyffels/transloco-keys-manager/marker";

export class Stock implements IStock {
  id: number = 0;
  subscriber_id: number = 0;
  product_id: number = 0;
  product_sku: string = '';
  product_ean: string = '';
  product_name: string = '';
  product_description: string = '';

  product: Product;

  get FullName(): string {
    if (this.product_sku) {
      return `${this.product_name} (${this.product_sku})`;
    } else {
      return this.product_name;
    }
  }

  serialnumber: string = '';
  purchaseprice: number = 0;
  salesprice: number = 0;
  warranty: number = 0;

  get Warranty(): string {
    return this.warranty === null || this.warranty === undefined ? null : `${this.warranty} ${translate(marker('label.stock.warranty.months', 'Maanden'))}`;
  }

  reception_id: number = 0;
  reception: PurchaseInvoice;
  invoice_id: number = 0;
  invoice: Invoice;
  productDeliveryId: number = 0;
  // productDelivery: ProductDelivery;
  productReceptionId: number = 0;
  // productReception: ProductReception;

  status: StockStatusEnum = StockStatusEnum.STOCK;

  get Status(): string {
    return Stock.getStatus(this.status);
  }

  static getStatus(status: StockStatusEnum) {
    switch (status) {
      case StockStatusEnum.STOCK:
        return translate(marker('label.stock.status.stock', 'In Stock'));
      case StockStatusEnum.DEFECT:
        return translate(marker('label.stock.status.defect', 'Defect'));
      case StockStatusEnum.LOANED:
        return translate(marker('label.stock.status.loaned', 'Uitgeleend'));
      case StockStatusEnum.DESTROYED:
        return translate(marker('label.stock.status.destroyed', 'Vernietigd'));
      case StockStatusEnum.USED:
        return translate(marker('label.stock.status.used', 'Gebruikt'));
      case StockStatusEnum.SOLD:
        return translate(marker('label.stock.status.sold', 'Verkocht'));
    }
  }

  created: string = "";

  private _created: Date;

  get Created() {return this._created;}

  set Created(date: Date) {
    this._created = date;
    this.created = SetDateTimeScalar(date);
  }

  updated: string = "";
  private _updated: Date;

  get Updated() {return this._updated;}

  set Updated(date: Date) {
    this._updated = date;
    this.updated = SetDateTimeScalar(date);
  }

  constructor(stock: IStock = null) {
    if (stock === null) {
      return;
    }

    constructFromInterface(this, stock);

    if (stock.created) {this._created = GetDateTimeScalar(stock.created);}
    if (stock.updated) {this._updated = GetDateTimeScalar(stock.updated);}
    if (stock.product) {
      this.product = new Product(stock.product);
    }
    if (stock.reception) {
      this.reception = new PurchaseInvoice(stock.reception);
    }
    if (stock.invoice) {
      this.invoice = new Invoice(stock.invoice);
    }
  }

  convertToInput(): IStockInput {
    return {
      id: this.id,
      status: this.status,
    };
  }

  createMutationInput(originalStock: Stock): IStockMutationInput {
    let mutationInput: IStockMutationInput = {id: this.id, stock: null};
    mutationInput.stock = _.isEqual(this.convertToInput(), originalStock?.convertToInput()) ? null : this.convertToInput();

    return mutationInput;
  }
}

export enum StockStatusEnum {
  STOCK = 1,
  SOLD = 2,
  USED = 3,
  LOANED = 4,
  DEFECT = 5,
  DESTROYED = 6,
}
