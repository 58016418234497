import {constructFromInterface} from 'app/core/logic/map.logic';
import _ from 'lodash';
import {IEntityEmail, IEntityEmailInput} from "../../../core/graphql/generated/types";
import {GetDateTimeScalar, SetDateTimeScalar} from "../../../core/logic/date-scalars";

export class EntityEmail implements IEntityEmail {
  id: number = 0;
  subscriberId: number = 0;
  entityId: number = 0;
  email: string = '';
  description: string = '';
  isInvoice: boolean = false;
  isOrder: boolean = false;
  isQuote: boolean = false;
  isInvoiceReminder: boolean = false;
  isWorkPerformance: boolean = false;
  onlyWhenNoContactSelected: boolean = false;
  created: string = "";

  private _created: Date;

  get Created() {return this._created;}

  set Created(date: Date) {
    this._created = date;
    this.created = SetDateTimeScalar(date);
  }

  updated: string = "";
  private _updated: Date;

  get Updated() {return this._updated;}

  set Updated(date: Date) {
    this._updated = date;
    this.updated = SetDateTimeScalar(date);
  }

  isValid(): boolean {
    return this.email && this.email.trim().length > 0;
  }

  constructor(email: IEntityEmail = null) {
    if (email === null) {
      return;
    }

    constructFromInterface(this, email);

    if (email.created) {this._created = GetDateTimeScalar(email.created);}
    if (email.updated) {this._updated = GetDateTimeScalar(email.updated);}
  }

  convertToInput(): IEntityEmailInput {
    return {
      id: !_.isNil(this.id) ? +this.id : null,
      entityId: !_.isNil(this.entityId) ? +this.entityId : null,
      email: !_.isNil(this.email) ? '' + this.email : null,
      description: !_.isNil(this.description) ? '' + this.description : null,
      isInvoice: !!this.isInvoice,
      isOrder: !!this.isOrder,
      isQuote: !!this.isQuote,
      isInvoiceReminder: !!this.isInvoiceReminder,
      isWorkPerformance: !!this.isWorkPerformance,
      onlyWhenNoContactSelected: !!this.onlyWhenNoContactSelected,
    };
  }

  static new(): EntityEmail {
    return new EntityEmail({
      created: null,
      description: null,
      email: null,
      entityId: null,
      id: null,
      isInvoice: true,
      isOrder: true,
      isQuote: true,
      isInvoiceReminder: true,
      isWorkPerformance: true,
      onlyWhenNoContactSelected: true,
      subscriberId: null,
      updated: null,
    });
  }
}
