import {constructFromInterface} from 'app/core/logic/map.logic';
import {IOctopusIntigration} from "../../../core/graphql/generated/types";


export class OctopusIntigration implements IOctopusIntigration {
  id: number = 0;
  intigrationOctopusEnabled: boolean = false;
  intigrationOctopusLogin: string = '';
  intigrationOctopusDescription: string = '';
  intigrationOctopusVatNr: string = '';
  intigrationOctopusOnlyAccountant: boolean = false;

  constructor(octopus: IOctopusIntigration = null) {
    if (octopus === null) {
      return;

    }
    constructFromInterface(this, octopus);
  }
}
