import _ from 'lodash';
import moment from 'moment-timezone';
import {User} from '../entity/user';
import {Subscriber} from '../entity/subscriber';
import {constructFromInterface} from 'app/core/logic/map.logic';
import {LedgerExportTypeEnum} from './ledger-export';
import {Invoice} from '../document/invoice';
import {Creditnote} from '../document/creditnote';
import {PurchaseInvoice} from '../document/purchase-invoice';
import {PurchaseCreditnote} from '../document/purchase-creditnote';
import {ICreditnote, IInvoice, ILedgerImport, ILedgerImportInput, ILedgerImportMutationInput, ILedgerImportSubline, IPurchaseCreditnote, IPurchaseInvoice} from "../../../core/graphql/generated/types";
import {GetDateScalar, GetDateTimeScalar, SetDateScalar, SetDateTimeScalar} from "../../../core/logic/date-scalars";


export enum LedgerImportSublineDocumentTypeEnum {
  SALESINVOICE = 1,
  SALESCREDITNOTE = 2,
  PURCHASEINVOICE = 3,
  PURCHASECREDITNOTE = 4,
}

export enum LedgerImportSublineStatusEnum {
  UNPROCESSED = 1,
  PROCESSING = 2,
  UNCHANGED = 3,
  CHANGED = 4,
  IMPORTED = 5,
  FAILED = 6,
}

export class LedgerImport implements ILedgerImport {
  id: number = 0;
  subscriberId: number = 0;
  subscriber: Subscriber;
  userId: number = 0;
  userName: string = '';
  user: User;
  type: LedgerExportTypeEnum;
  start: string = "";
  private _start: Date;

  get Start() {return this._start;}

  set Start(date: Date) {
    this._start = date;
    this.start = SetDateScalar(date);
  }

  end: string = "";
  private _end: Date;

  get End() {return this._end;}

  set End(date: Date) {
    this._end = date;
    this.end = SetDateScalar(date);
  }

  created: string = "";

  private _created: Date;

  get Created() {return this._created;}

  set Created(date: Date) {
    this._created = date;
    this.created = SetDateTimeScalar(date);
  }

  started: string = "";
  private _started: Date;

  get Started() {return this._started;}

  set Started(date: Date) {
    this._started = date;
    this.started = SetDateTimeScalar(date);
  }

  completed: string = "";
  private _completed: Date;

  get Completed() {return this._completed;}

  set Completed(date: Date) {
    this._completed = date;
    this.completed = SetDateTimeScalar(date);
  }

  name: string = '';

  sublines: LedgerImportSubline[];

  get Validation(): boolean {
    return moment(this.start)
      .isValid() && moment(this.end)
      .isValid();
  }

  constructor(ledgerImport: ILedgerImport = null) {
    if (ledgerImport === null) {
      return;
    }

    constructFromInterface(this, ledgerImport);

    if (ledgerImport.start) {this._start = GetDateScalar(ledgerImport.start);}
    if (ledgerImport.end) {this._start = GetDateScalar(ledgerImport.end);}
    if (ledgerImport.created) {this._created = GetDateTimeScalar(ledgerImport.created);}
    if (ledgerImport.started) {this._started = GetDateTimeScalar(ledgerImport.started);}
    if (ledgerImport.completed) {this._completed = GetDateTimeScalar(ledgerImport.completed);}
    if (ledgerImport.subscriber) {
      this.subscriber = new Subscriber(ledgerImport.subscriber);
    }
    if (ledgerImport.user) {
      this.user = new User(ledgerImport.user);
    }
    if (ledgerImport.type) {
      this.type = ledgerImport.type;
    }
    if (ledgerImport.sublines) {
      this.sublines = ledgerImport.sublines.map((s) => new LedgerImportSubline(s));
    }
  }

  public static new(): LedgerImport {
    return new LedgerImport({
      id: null,
      subscriberId: null,
      subscriber: null,
      userId: null,
      userName: null,
      user: null,
      type: null,
      start: null,
      end: SetDateScalar(new Date()),
      created: null,
      started: null,
      completed: null,
      name: '',
      sublines: null,
    });
  }

  public convertToInput(): ILedgerImportInput {
    return {
      id: this.id,
      name: this.name,
      type: this.type,
      start: SetDateScalar(this.start ? moment(this.start)
        .toDate() : null),
      end: SetDateScalar(this.end ? moment(this.end)
        .toDate() : null),
    };
  }

  public createMutationInput(originalImport: LedgerImport = null): ILedgerImportMutationInput {
    let mutationInput: ILedgerImportMutationInput = {id: this.id, import: null};
    mutationInput.import = _.isEqual(this.convertToInput(), originalImport?.convertToInput()) ? null : this.convertToInput();

    return mutationInput;
  }
}

export class LedgerImportSubline implements ILedgerImportSubline {
  id: string = '';
  subscriberId: number = 0;
  importId: number = 0;
  timestamp: string = "";
  private _timestamp: Date;

  get Timestamp() {return this._timestamp;}

  set Timestamp(date: Date) {
    this._timestamp = date;
    this.timestamp = SetDateTimeScalar(date);
  }

  documentType: LedgerImportSublineDocumentTypeEnum = LedgerImportSublineDocumentTypeEnum.SALESINVOICE;
  documentId: number = 0;
  document: Invoice | Creditnote | PurchaseInvoice | PurchaseCreditnote;
  status: LedgerImportSublineStatusEnum = LedgerImportSublineStatusEnum.UNPROCESSED;
  statusMessage: string = '';
  raw: string = '';

  constructor(subline: ILedgerImportSubline = null) {
    if (subline === null) {
      return;
    }

    constructFromInterface(this, subline);
    if (subline.timestamp) {this._timestamp = GetDateTimeScalar(subline.timestamp);}
    if (subline.document) {
      switch (subline.document.__typename) {
        case 'Invoice':
          this.document = new Invoice(subline.document as IInvoice);
          break;
        case 'Creditnote':
          this.document = new Creditnote(subline.document as ICreditnote);
          break;
        case 'PurchaseInvoice':
          this.document = new PurchaseInvoice(subline.document as IPurchaseInvoice);
          break;
        case 'PurchaseCreditnote':
          this.document = new PurchaseCreditnote(subline.document as IPurchaseCreditnote);
          break;
        default:
          this.document = null;
          break;
      }
    }
  }
}

export interface LedgerImportDataCompare {
  id: number;
  key: string;
  currentValueLabel: string;
  currentValue: string;
  newValueLabel: string;
  newValue: string;
  needDownload: boolean;
  downloadValue: string;
}

export interface LedgerImportDataCompareLine {
  lineId: number;
  name: string;
  dataCompare: LedgerImportDataCompare[];
}
