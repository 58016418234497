import {constructFromInterface} from "app/core/logic/map.logic";
import {IAdmisolIntigration} from "../../../core/graphql/generated/types";


export class AdmisolIntegration implements IAdmisolIntigration {
  id: number = 0;
  integrationAdmisolMailboxEnabled: boolean = false;
  integrationAdmisolSalesInvoiceMailbox: string = "";
  integrationAdmisolPurchaseInvoiceMailbox: string = "";
  integrationAdmisolCreditnoteMailbox: string = "";

  constructor(admisol: IAdmisolIntigration = null) {
    if (admisol === null) {
      return;
    }

    constructFromInterface(this, admisol);
  }
}
