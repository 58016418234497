import { Injectable } from '@angular/core';
import { Translation } from 'app/shared/backend-logic/translation';

@Injectable({
  providedIn: 'root',
})
export class TranslationService extends Translation {
  constructor() {
    super();
  }
}
