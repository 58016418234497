import {constructFromInterface} from 'app/core/logic/map.logic';
import {Invoice} from '../document/invoice';
import {PurchaseInvoice} from '../document/purchase-invoice';
import {IBankTransaction, IFinancialAccount, IFinancialAccountBalance, IFinancialAccountLink, IFinancialAccountTransaction, IFinancialAccountTransactionCreditorAccount, IFinancialAccountTransactionDebtorAccount, IFinancialInstitution} from "../../../core/graphql/generated/types";
import {GetDateScalar, GetDateTimeScalar, SetDateScalar, SetDateTimeScalar} from "../../../core/logic/date-scalars";

export interface IFinancialRequisition {
  id: string;
  created: Date;
  redirect: string;
  status: 'CR' | 'LN' | 'EX' | 'RJ' | 'UA' | 'GA' | 'SA' | 'GC' | 'SU'; // Created | Linked | Expired | Rejected | Under authentication | Granting access | Selecting accounts | Giving consent | Suspended
  institution_id: string;
  agreement: string;
  reference: string;
  accounts: string[];
  link: string;
  ssn: string;
}

export class FinancialAccountLink implements IFinancialAccountLink {
  id: string = '';
  link: string = '';

  constructor(institution: IFinancialAccountLink = null) {
    if (institution === null) {
      return;
    }

    constructFromInterface(this, institution);
  }
}

export class FinancialInstitution implements IFinancialInstitution {
  id: string = '';
  name: string = '';
  bic: string = '';
  transaction_total_days: number = 0;
  countries: string[] = [];
  logo: string = '';

  constructor(institution: IFinancialInstitution = null) {
    if (institution === null) {
      return;
    }

    constructFromInterface(this, institution);
  }
}

export class FinancialRequisition implements FinancialRequisition {
  id: string = '';
  created: Date;
  redirect: string = '';
  status: 'CR' | 'LN' | 'EX' | 'RJ' | 'UA' | 'GA' | 'SA' | 'GC' | 'SU';
  institution_id: string = '';
  agreement: string = '';
  reference: string = '';
  accounts: string[] = [];
  link: string = '';
  ssn: string = '';

  constructor(requisition: IFinancialRequisition) {
    constructFromInterface(this, requisition);
    this.status = requisition.status;
  }
}

export class BankTransaction implements IBankTransaction {
  id: number = 0;
  subscriber_id: number = 0;
  bank_id: number = 0;
  amount: number = 0;
  date: string = "";
  private _date: Date;

  get Date() {return this._date;}

  set Date(date: Date) {
    this._date = date;
    this.date = SetDateScalar(date);
  }

  iban: string = '';
  name: string = '';
  reference: string = '';
  transactionId: string = '';
  matched: boolean = false;
  matchedSource: BankTransactionMatchedSourceEnum = BankTransactionMatchedSourceEnum.AUTOMATIC;
  matchedDate: string = "";
  private _matchedDate: Date;

  get MatchedDate() {return this._matchedDate;}

  set MatchedDate(date: Date) {
    this._matchedDate = date;
    this.matchedDate = SetDateTimeScalar(date);
  }

  matchedInvoiceId: number = 0;
  matchedInvoice: Invoice;
  matchedReceptionId: number = 0;
  matchedReception: PurchaseInvoice;
  matchedOnPaymentRef: boolean = false;
  matchedOnName: boolean = false;
  matchedOnBankAccount: boolean = false;
  matchedOnAmount: boolean = false;
  matchedCompleted: boolean = false;

  constructor(transaction: IBankTransaction = null) {
    if (transaction === null) {
      return;
    }

    constructFromInterface(this, transaction);

    if (transaction.date) {this._date = GetDateScalar(transaction.date);}
    if (transaction.matchedDate) {this._matchedDate = GetDateTimeScalar(transaction.matchedDate);}
    if (transaction.matchedInvoice) {
      this.matchedInvoice = new Invoice(transaction.matchedInvoice);
    }
    if (transaction.matchedReception) {
      this.matchedReception = new PurchaseInvoice(transaction.matchedReception);
    }
  }
}

export class FinancialAccountTransaction implements IFinancialAccountTransaction {
  transactionAmount: { amount: number; currency: string };
  bookingDate: string = "";
  private _bookingDate: Date;

  get BookingDate() {return this._bookingDate;}

  set BookingDate(date: Date) {
    this._bookingDate = date;
    this.bookingDate = SetDateScalar(date);
  }

  debtorAccount: IFinancialAccountTransactionDebtorAccount;
  debtorName: string;
  creditorAccount: IFinancialAccountTransactionCreditorAccount;
  creditorName: string;
  remittanceInformationUnstructured: string;
  remittanceInformationStructured: string;
  // remittanceInformationStructuredArray: {
  //   creditorReferenceInformation: {
  //     type: {
  //       code: string;
  //       issuer: string;
  //     };
  //     reference: string;
  //   };
  //   debtorReferenceInformation: {
  //     type: {
  //       code: string;
  //       issuer: string;
  //     };
  //     reference: string;
  //   };
  // };
  remittanceInformationUnstructuredArray: string[];
  additionalInformation: string;
  transactionId: string;
  internalTransactionId: string;
  valueDate: string = "";
  private _valueDate: Date;

  get ValueDate() {return this._valueDate;}

  set ValueDate(date: Date) {
    this._valueDate = date;
    this.valueDate = SetDateScalar(date);
  }

  constructor(transaction: IFinancialAccountTransaction = null) {
    if (transaction === null) {
      return;
    }

    this.transactionAmount = transaction.transactionAmount;
    this.bookingDate = transaction.bookingDate;
    this.debtorAccount = transaction.debtorAccount;
    this.debtorName = transaction.debtorName;
    this.creditorAccount = transaction.creditorAccount;
    this.creditorName = transaction.creditorName;
    this.remittanceInformationUnstructured = transaction.remittanceInformationUnstructured;
    this.remittanceInformationStructured = transaction.remittanceInformationStructured;
    // if (transaction.remittanceInformationStructuredArray) {
    //   this.remittanceInformationStructuredArray = transaction.remittanceInformationStructuredArray;
    // }
    this.remittanceInformationUnstructuredArray = transaction.remittanceInformationUnstructuredArray;
    this.additionalInformation = transaction.additionalInformation;
    this.transactionId = transaction.transactionId;
    this.internalTransactionId = transaction.internalTransactionId;
    this.valueDate = transaction.valueDate;

    if (transaction.bookingDate) {this._bookingDate = GetDateScalar(transaction.bookingDate);}
    if (transaction.valueDate) {this._valueDate = GetDateScalar(transaction.valueDate);}
  }
}

export class FinancialAccountBalance implements IFinancialAccountBalance {
  balanceAmount: { amount: number; currency: string };
  balanceType: string;

  constructor(balance: IFinancialAccountBalance) {
    if (balance === null) {
      return;
    }

    this.balanceAmount = balance.balanceAmount;
    this.balanceType = balance.balanceType;
  }
}

export class FinancialAccount implements IFinancialAccount {
  id: string = '';
  created: string = "";

  private _created: Date;

  get Created() {return this._created;}

  set Created(date: Date) {
    this._created = date;
    this.created = SetDateTimeScalar(date);
  }

  last_accessed: string = "";
  private _last_accessed: Date;

  get Last_accessed() {return this._last_accessed;}

  set Last_accessed(date: Date) {
    this._last_accessed = date;
    this.last_accessed = SetDateTimeScalar(date);
  }

  iban: string = '';
  institution_id: string = '';
  status: string = '';
  requisition_id: string = '';

  institution: FinancialInstitution;
  balances: FinancialAccountBalance[] = [];
  transactions: FinancialAccountTransaction[] = [];

  constructor(account: IFinancialAccount = null) {
    if (account === null) {
      return;
    }

    constructFromInterface(this, account);

    if (account.last_accessed) {this._last_accessed = GetDateTimeScalar(account.last_accessed);}
    if (account.created) {this._created = GetDateTimeScalar(account.created);}
    if (account.institution) {
      this.institution = new FinancialInstitution(account.institution);
    }

    if (account.balances) {
      this.balances = account.balances.map((x) => new FinancialAccountBalance(x));
    }
    if (account.transactions) {
      this.transactions = account.transactions.map((x) => new FinancialAccountTransaction(x));
    }
  }
}

export enum BankTransactionMatchedSourceEnum {
  AUTOMATIC = 1,
  MANUAL = 2,
}
