import {Address} from 'app/shared/models/relation/address';
import {Company} from 'app/shared/models/entity/company';
import {Country} from 'app/shared/models/general/country';
import {MutationInputObject} from 'app/shared/models/system/mutation-object';
import _ from 'lodash';
import moment from 'moment';
import {Product} from 'app/shared/models/article/product';
import {Relation} from '../relation/relation.class';
import {Email} from '../mail/email';
import {Document} from '../general/document';
import {constructFromInterface} from 'app/core/logic/map.logic';
import {IDocumentMutationInput, IPurchaseOrder, IPurchaseOrderInput, IPurchaseOrderMutationInput, IPurchaseOrderSubline, IPurchaseOrderSublineInput, IPurchaseOrderSublineMutationInput} from "../../../core/graphql/generated/types";
import {GetDateScalar, GetDateTimeScalar, SetDateScalar, SetDateTimeScalar} from "../../../core/logic/date-scalars";
import {countries} from "../../data/country";
import {translate} from "@jsverse/transloco";
import {marker} from "@nyffels/transloco-keys-manager/marker";


export class PurchaseOrder implements IPurchaseOrder {
  id: number = 0;
  subscriberId: number = 0;
  number: number = 0;
  Number: string = '';

  companyId: number = 0;
  companyName: string = '';
  companyVatNumber: string = '';
  companyAddressStreet: string = '';
  companyAddressNumber: string = '';
  companyAddressZip: string = '';
  companyAddressCity: string = '';
  companyAddressCounty: string = '';
  companyAddressCountryId: number = 0;
  companyAddressCountry: Country;

  get company_address(): Address {
    return new Address({
      id: null,
      street: this.companyAddressStreet,
      number: this.companyAddressNumber,
      city: this.companyAddressCity,
      zip: this.companyAddressZip,
      county: this.companyAddressCounty,
      country_id: this.companyAddressCountryId,
      country: this.companyAddressCountry,
      default: null,
      subscriber_id: this.subscriberId,
    });
  }

  company: Company;

  relationId: number = 0;
  relationName: string = '';
  relationVatNumber: string = '';
  relationAddressStreet: string = '';
  relationAddressNumber: string = '';
  relationAddressZip: string = '';
  relationAddressCity: string = '';
  relationAddressCounty: string = '';
  relationAddressCountryId: number = 0;
  relationAddressCountry: Country;

  get supplier_address(): Address {
    return new Address({
      id: null,
      street: this.relationAddressStreet,
      number: this.relationAddressNumber,
      city: this.relationAddressCity,
      zip: this.relationAddressZip,
      county: this.relationAddressCounty,
      country_id: this.relationAddressCountryId,
      country: this.relationAddressCountry,
      default: null,
      subscriber_id: this.subscriberId,
    });
  }

  relation: Relation;

  value: number = 0;
  date: string = "";
  private _date: Date;

  get Date() {return this._date;}

  set Date(date: Date) {
    this._date = date;
    this.date = SetDateScalar(date);
  }

  intnote: string = '';
  note: string = '';

  status: PurchaseOrderStatusEnum = PurchaseOrderStatusEnum.CONCEPT;

  get Status(): string {
    switch (this.status) {
      case PurchaseOrderStatusEnum.PARTIALLYRECEIVED:
        return translate(marker('label.purchaseOrder.status.partiallyReceived', 'Gedeeltelijk ontvangen'));
      case PurchaseOrderStatusEnum.CONCEPT:
        return translate(marker('label.purchaseOrder.status.concept', 'Concept'));
      case PurchaseOrderStatusEnum.ORDERED:
        return translate(marker('label.purchaseOrder.status.ordered', 'Besteld'));
      case PurchaseOrderStatusEnum.RECEIVED:
        return translate(marker('label.purchaseOrder.status.received', 'Ontvangen'));
    }
  }

  created: string = "";
  private _created: Date;

  get Created() {return this._created;}

  set Created(date: Date) {
    this._created = date;
    this.created = SetDateTimeScalar(date);
  }

  updated: string = "";
  private _updated: Date;

  get Updated() {return this._updated;}

  set Updated(date: Date) {
    this._updated = date;
    this.updated = SetDateTimeScalar(date);
  }

  vatId: number = 0;

  sublines: PurchaseOrderSubline[] = [];
  documents: Document[] = [];
  emails: Email[] = [];

  get Valid(): boolean {
    return (
      this.companyName != null &&
      this.companyName.trim().length > 0 &&
      this.companyVatNumber != null &&
      this.companyVatNumber.trim().length > 0 &&
      this.relationId != null &&
      this.relationId > 0 &&
      this.relationName != null &&
      this.relationName.trim().length > 0 &&
      this.value !== null &&
      this.date !== null
    );
  }

  constructor(purchaseOrder: IPurchaseOrder) {
    constructFromInterface(this, purchaseOrder);

    if (purchaseOrder.date) {
      this._date = GetDateScalar(this.date);
    }
    if (purchaseOrder.created) {
      this._created = GetDateTimeScalar(purchaseOrder.created);
    }
    if (purchaseOrder.updated) {
      this._updated = GetDateTimeScalar(purchaseOrder.updated);
    }
    if (purchaseOrder.companyAddressCountryId) {
      this.companyAddressCountry = new Country(countries.find(x => x.id == purchaseOrder.companyAddressCountryId));
    }
    if (purchaseOrder.company) {
      this.company = new Company(purchaseOrder.company);
    }
    if (purchaseOrder.relationAddressCountryId) {
      this.relationAddressCountry = new Country(countries.find(x => x.id == purchaseOrder.relationAddressCountryId));
    }
    if (purchaseOrder.relation) {
      this.relation = new Relation(purchaseOrder.relation);
    }
    if (purchaseOrder.sublines) {
      this.sublines = purchaseOrder.sublines.map((x) => new PurchaseOrderSubline(x));
    }
    if (purchaseOrder.documents) {
      this.documents = purchaseOrder.documents.map((x) => new Document(x));
    }
    if (purchaseOrder.emails) {
      this.emails = purchaseOrder.emails.map((x) => new Email(x));
    }
  }

  public calculateValue(): void {
    this.value = _.sumBy(this.sublines, (x) => +x.price);
  }

  public static new(): PurchaseOrder {
    return new PurchaseOrder({
      id: null,
      subscriberId: null,
      number: null,
      Number: null,
      companyId: null,
      companyName: null,
      companyVatNumber: null,
      companyAddressStreet: null,
      companyAddressNumber: null,
      companyAddressZip: null,
      companyAddressCity: null,
      companyAddressCounty: null,
      companyAddressCountryId: null,
      companyAddressCountry: null,
      company: null,
      relationId: null,
      relationName: null,
      relationVatNumber: null,
      relationAddressStreet: null,
      relationAddressNumber: null,
      relationAddressZip: null,
      relationAddressCity: null,
      relationAddressCounty: null,
      relationAddressCountryId: null,
      relationAddressCountry: null,
      relation: null,
      value: 0,
      intnote: null,
      note: null,
      date: SetDateScalar(new Date()),
      status: PurchaseOrderStatusEnum.CONCEPT,
      created: null,
      updated: null,
      sublines: [],
      documents: [],
      emails: [],
    });
  }

  public convertToInput(): IPurchaseOrderInput {
    return {
      id: this.id,
      companyId: this.companyId,
      companyName: this.companyName,
      companyVatNumber: this.companyVatNumber,
      companyAddressStreet: this.companyAddressStreet,
      companyAddressNumber: this.companyAddressNumber,
      companyAddressZip: this.companyAddressZip,
      companyAddressCity: this.companyAddressCity,
      companyAddressCounty: this.companyAddressCounty,
      companyAddressCountryId: this.companyAddressCountryId,
      relationId: this.relationId,
      relationName: this.relationName,
      relationVatNumber: this.relationVatNumber,
      relationAddressStreet: this.relationAddressStreet,
      relationAddressNumber: this.relationAddressNumber,
      relationAddressZip: this.relationAddressZip,
      relationAddressCity: this.relationAddressCity,
      relationAddressCounty: this.relationAddressCounty,
      relationAddressCountryId: this.relationAddressCountryId,
      value: +this.value,
      intnote: this.intnote,
      note: this.note,
      date: SetDateScalar(this.date ? moment(this.date)
        .toDate() : null),
      status: this.status
    };
  }

  public createMutationInput(sublines: MutationInputObject<PurchaseOrderSubline>, documents: MutationInputObject<Document>, originalPurchaseOrder: PurchaseOrder = null): IPurchaseOrderMutationInput {
    let mutationInput: IPurchaseOrderMutationInput = {id: this.id, purchaseOrder: null, sublines: null, documents: null};
    mutationInput.purchaseOrder = _.isEqual(this.convertToInput(), originalPurchaseOrder?.convertToInput()) ? null : this.convertToInput(); /* Check if purchaseorder is updated */

    /* Set sublines */
    mutationInput.sublines = {
      deletes: sublines.deletes.map((x) => Number(x)),
      updates: sublines.updates.map((x) => {
        return {
          id: x.id,
          subline: x.convertToInput(),
        } as IPurchaseOrderSublineMutationInput;
      }),
    };

    /* Set documents */
    mutationInput.documents = {
      deletes: documents.deletes.map((x) => Number(x)),
      updates: documents.updates.map((x) => {
        return {
          id: x.id,
          document: x.convertToInput(),
        } as IDocumentMutationInput;
      }),
    };

    return mutationInput;
  }
}

export class PurchaseOrderSubline implements IPurchaseOrderSubline {
  id: number = 0;
  subscriberId: number = 0;
  purchaseorderId: number = 0;

  articleId: number = 0;
  articleSku: string = '';
  articleName: string = '';
  article: Product;

  amount: number = 0;
  unitPrice: number = 0;
  price: number = 0;
  note: string = '';
  created: string = "";
  private _created: Date;

  get Created() {return this._created;}

  set Created(date: Date) {
    this._created = date;
    this.created = SetDateTimeScalar(date);
  }

  updated: string = "";
  private _updated: Date;

  get Updated() {return this._updated;}

  set Updated(date: Date) {
    this._updated = date;
    this.updated = SetDateTimeScalar(date);
  }

  order: number = 0;
  unit: string = '';

  get Valid(): boolean {
    return this.articleName != null && this.articleName.trim() != '' && this.amount !== null;
  }

  constructor(subline: IPurchaseOrderSubline) {
    constructFromInterface(this, subline);

    if (subline.created) {
      this._created = GetDateTimeScalar(subline.created);
    }
    if (subline.updated) {
      this._updated = GetDateTimeScalar(subline.updated);
    }
    if (subline.article) {
      this.article = new Product(subline.article);
    }
  }

  public static new(): PurchaseOrderSubline {
    return new PurchaseOrderSubline({
      id: null,
      subscriberId: null,
      purchaseOrderId: null,
      articleId: null,
      articleSku: null,
      articleName: null,
      article: null,
      amount: 1,
      unitPrice: 0,
      price: 0,
      note: null,
      created: null,
      updated: null,
      order: null,
      unit: null,
    });
  }

  public convertToInput(): IPurchaseOrderSublineInput {
    return {
      id: this.id,
      articleId: +this.articleId,
      articleName: this.articleName,
      articleSku: this.articleSku,
      amount: +this.amount,
      price: +this.price,
      note: this.note,
      order: +this.order,
      unit: this.unit,
    };
  }

  public calculateSublineValuesFromAmount(): void {
    this.price = +(this.price ?? 0);
    this.unitPrice = +(this.unitPrice ?? 0);
    this.amount = +(this.amount ?? 1);

    this.unitPrice = this.unitPrice ?? 0;
    this.price = +(+this.unitPrice * +(this.amount ?? 0)).toFixed(2);
  }

  public calculateSublineValuesFomUnitPrice(): void {
    this.price = +(this.price ?? 0);
    this.unitPrice = +(this.unitPrice ?? 0);
    this.amount = +(this.amount ?? 1);

    this.price = +(+this.unitPrice * +(this.amount ?? 0)).toFixed(2);
  }

  public calculateSublineValuesFromPurchasePrice(): void {
    // this.price = +(this.price ?? 0);
    this.unitPrice = +(this.unitPrice ?? 0);
    this.amount = +(this.amount ?? 1);

    this.unitPrice = +(+(this.amount ?? 0) == 0 ? 0 : +this.price / +this.amount).toFixed(2);
  }
}

export enum PurchaseOrderStatusEnum {
  CONCEPT = 1,
  ORDERED = 2,
  PARTIALLYRECEIVED = 3,
  RECEIVED = 4,
}
