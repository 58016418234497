import {constructFromInterface} from 'app/core/logic/map.logic';
import {ITask} from "../../../core/graphql/generated/types";
import {GetDateTimeScalar, SetDateTimeScalar} from 'app/core/logic/date-scalars';

export class Task implements ITask {
  id: number = 0;
  subscriber_id: number = 0;
  type: string = '';
  parameter: string = '';
  result: string = '';
  resultLabel: string = '';
  finished: string = "";
  private _finished: Date;

  get Finished() {return this._finished;}

  set Finished(date: Date) {
    this._finished = date;
    this.finished = SetDateTimeScalar(date);
  }

  status: TaskStatusEnum = TaskStatusEnum.NEW;
  visible: boolean = false;
  read: boolean = false;
  created: string = "";

  private _created: Date;

  get Created() {return this._created;}

  set Created(date: Date) {
    this._created = date;
    this.created = SetDateTimeScalar(date);
  }

  updated: string = "";
  private _updated: Date;

  get Updated() {return this._updated;}

  set Updated(date: Date) {
    this._updated = date;
    this.updated = SetDateTimeScalar(date);
  }

  constructor(task: ITask = null) {
    if (task === null) {
      return;
    }

    constructFromInterface(this, task);

    if (task.finished) {this._finished = GetDateTimeScalar(task.finished);}
    if (task.created) {this._created = GetDateTimeScalar(task.created);}
    if (task.updated) {this._updated = GetDateTimeScalar(task.updated);}
  }
}

export enum TaskStatusEnum {
  NEW = 1,
  PROCESSING = 2,
  COMPLETED = 3,
  FAILED = 4,
}
